<template>
  <div class="UnitMaintenance">
    <div style="display: flex;justify-content:space-between;width: 98%;margin:0 auto;margin-bottom: 20px">
      <div class="query-input">
        <span class="query-key">关键字:</span>
        <el-input placeholder="请输入关键字搜索" v-model="listQueryPage.Key"></el-input>
        <el-button icon="el-icon-search" style="margin-left: 20px" @click="query">查询</el-button>
      </div>
      <div>
        <el-button v-if="admin != '00000000-0000-0000-0000-000000000000' && flag !== false" type="primary"
                   @click="addCompanyDialog">添加单位
        </el-button>
      </div>
    </div>
    <div style="height: 450px;margin-bottom: 10px;">
      <el-table border :data="tableData" :header-cell-style="{ background: 'rgb(240, 247, 253)' }" height="450px"
                style="width: 100%;height: 450px;">
        <el-table-column v-if="admin == '00000000-0000-0000-0000-000000000000'" prop="OrganizationName" label="组织名称"
                         min-width="80" align="center">
        </el-table-column>
        <el-table-column prop="UnitName" label="单位">
        </el-table-column>
        <el-table-column label="操作" width="180"
                         v-if="admin != '00000000-0000-0000-0000-000000000000' && RoleType == 'D'&& flag !== false">
          <template slot-scope="scope">
            <el-link @click="EditUnitMaintenance(scope.row)" type="primary" :underline="false">
              <i class="el-icon-edit-outline"></i>修改
            </el-link>
            <el-link :underline="false" style="margin-left: 11px" @click="DelUnitMaintenance(scope.row)"><i
                class="el-icon-delete"></i>删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <Pagination :total="total" :page.sync="listQueryPage.page" :limit.sync="listQueryPage.rows"
                  @pagination="CompanyList">
      </Pagination>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">新增单位</span>
      </div>
      <div>
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="单位" prop="pass" ref="Formitem">
            <el-select class="item_select" v-model="ruleForm.UnitId" filterable remote :remote-method="remoteMethod"
                       v-el-select-loadmore="loadmore" placeholder="请选择">
              <el-option v-for="(item, index) in hospital" :key="index" :label="item.Name" :value="item.Id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="btn">
        <button @click="dialogVisible = false">取消</button>
        <button type="primary" @click="AddPreservation">保存</button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import ProjectManagement from "../../api/ProjectManagement"
import Pagination from "../../components/Pagination/index.vue"
import {local} from "../../utils/storage"

export default {
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
            '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition =
              this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
      admin: local.get("UserId"),
      RoleType: local.get('RoleType'),
      type: 1,
      hospital: [],
      keyword: "",
      tableData: [],
      dialogVisible: false,
      ruleForm: {
        ProjectId: this.$route.query.Id,
        UnitId: "",
        Id: "",
      },
      listQueryPage: {
        Key: '',
        page: 1,
        rows: 10,
      },
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 20,
      },
    }
  },
  props: ['flag'],
  components: {
    Pagination
  },
  created() {
    this.CompanyList('')
  },
  methods: {
    query() {
      this.CompanyList()
    },
    //删除单位
    DelUnitMaintenance(row) {
      const parameter = []
      parameter.push(row.Id)
      this.$confirm('此操作将永久删除该单位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ProjectManagement.DeleteCompanyData(parameter).then(res => {
          console.log(res);
          if (res.data.Status == 1) {
            this.tableData = this.tableData.filter(item => {
              return item.Id != row.Id
            })
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.CompanyList();
          } else {
            alert(res.data.Message)
          }
        })
      })
    },
    //打开编辑弹框
    async EditUnitMaintenance(row) {
      // await this.GetListCompany();
      await this.GetPageListCompanyData();
      const parameter = {
        Key: row.UnitName,
        page: this.listQuery.page,
        rows: this.listQuery.rows
      };
      ProjectManagement.GetPageListCompanyData(parameter).then(res => {
        if (res.data.Status == 1) {
          //去重
          let arrKeep = [...res.data.Data.rows, ...this.hospital];
          console.log(res.data.Data.rows, this.hospital, 'resitem')
          let map = new Map();
          for (let item of arrKeep) {
            if (!map.has(item.Id)) {
              map.set(item.Id, item)
            }
          }
          let arr = [...map.values()];
          this.hospital = arr;
          this.type = 2;
          this.ruleForm.UnitId = row.UnitId;
          this.ruleForm.Id = row.Id;
          this.dialogVisible = true;
        } else {
          alert(res.data.Message)
        }
      });
    },

    CompanyList() {
      const parameter = {
        ProjectId: [this.$route.query.Id],
        Key: this.listQueryPage.Key,
        page: this.listQueryPage.page,
        rows: this.listQueryPage.rows
      }
      ProjectManagement.CompanyListData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.tableData = [];
          this.total = res.data.Data.total
          this.tableData = res.data.Data.rows
        } else {
          alert(res.data.Message)
        }
        console.log(res, "单位列表");
      })
    },
    //保存
    AddPreservation() {
      if (this.type == 1) {
        ProjectManagement.AddCompanyData(this.ruleForm).then(res => {
          if (res.data.Status == 1) {
            this.tableData.push(res.data.Data)
            this.$message({
              type: 'success',
              message: '保存成功!'
            });
            this.CompanyList();
            this.dialogVisible = false;

          } else {
            alert(res.data.Message)
          }
          console.log(res);
        })
      } else {
        ProjectManagement.EditCompanyData(this.ruleForm).then(res => {
          if (res.data.Status == 1) {
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
            this.CompanyList();
            this.dialogVisible = false;
          } else {
            alert(res.data.Message)
          }
          console.log(res);
        })
      }
    },
    //远程搜索单位医
    remoteMethod(name) {
      this.listQuery.Key = name;
      this.hospital = [];
      this.listQuery.page = 1;
      this.GetPageListCompanyData();
    },
    loadmore() {
      this.listQuery.page += 1;
      this.GetPageListCompanyData();
    },
    addCompanyDialog() {
      this.hospital = [];
      this.listQuery.Key = '';
      this.listQuery.page = 1;
      this.listQuery.rows = 20;
      this.ruleForm.UnitId = '';
      this.type = 1;
      this.dialogVisible = true;
      this.GetPageListCompanyData();
    },
    //单位列表接口分页查询
    async GetPageListCompanyData() {
      const params = {
        Key: this.listQuery.Key,
        page: this.listQuery.page,
        rows: this.listQuery.rows
      };
      const {data} = await ProjectManagement.GetPageListCompanyData(params);
      if (data.Status == 1) {
        data.Data.rows.forEach(item => {
          this.hospital.push(item)
        })
        //   this.hospital = data.Data.rows;
      } else {
        alert(data.Message)
      }
    },
    //单位列表接口
    async GetListCompany(Name) {
      const parameter = {
        Key: Name,
      }
      const {data} = await ProjectManagement.GetListCompanyData(parameter);
      if (data.Status === 1) {
        this.hospital = data.Data;
      } else {
        alert(data.Message)
      }
      //  ProjectManagement.GetListCompanyData(parameter).then(res=>{
      //       if(res.data.Status==1){
      //         console.log("249jieshu")
      //       }else{

      //       }
      //       console.log(res,"医院");
      //  })
    },
  }
}
</script>
<style lang="scss" scoped>
.query-input {
  .query-key {
    line-height: 34px;
  }

  ::v-deep .el-input {
    width: 240px;
    height: 34px;

    .el-input__inner {
      width: 240px;
      height: 34px;
      font-size: 16px;
    }
  }
}

::v-deep .el-form-item {
  margin-bottom: initial;
  // ::v-deep .el-input__inner{
  //     width: 400px !important;
  // }
}

.item_select {
  width: 100%;
}

.UnitMaintenance {
  padding: 20px;
  box-sizing: border-box;
}


@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .UnitMaintenance {
    padding: 0 !important;
  }

  ::v-deep .el-dialog {
    width: 60% !important;
  }

}
</style>
