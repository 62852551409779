// 手机号
const phoneReg = /^1[3456789]\d{9}$/
//邮箱
const password=/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
// 身份证号
const regId = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
export function validatePhone(rule, value, callback) {
        if (value=='') {
            return callback(new Error('手机号码不能为空'));
          }
        if (!phoneReg.test(value)) {
            callback(new Error('请输入正确的手机号码'));
        }
        callback()
}
export function validateregId(rule, value, callback) {
    if (!regId.test(value)) {
        callback(new Error('请输入正确的身份证号'));
    }
    callback()
}
export function validatePassword(rule, value, callback) {
    if (value=='') {
        return callback(new Error('密码不能为空'));
    }
    if (!password.test(value)) {
        callback(new Error('至少八个字符，至少一个字母和一个数字'));
    }
    callback()
}
