// 问卷中心接口
import axios from "./Interceptor.js"
let address =window.global_config.BASE_URL2
let api = {
    SaveQuestionnaireIDData:(info)=>{//储存打开填写问卷id
        return axios({
            method:"post",
            url:`${address}Project/Patient/FollowUpTask/Fill`,
            data:info,
        })
    },
    GetPageListData:()=>{//问卷无分页
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/GetList`,
        })
    },
    QGetPageListData:(info)=>{//问卷中心分页
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/GetPageList`,
            data:info,
        })
    },
    ProjectGetPageListData:(info)=>{//项目问卷中心分页
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/GetPageList`,
            data:info,
        })
    },
    AddQuestionnaireData:()=>{//添加问卷
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/Add`,
        })
    },
    EditQuestionnaireData:()=>{//编辑问卷
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/Edit`,
        })
    },
    SeeQuestionnaireData:()=>{//查看问卷
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/Get`,
        })
    },
    DeleteQuestionnaireData:(id)=>{//删除问卷
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/Delete`,
            params:{id:id}
        })
    },
    PublicQuestionnaireData:(info)=>{//发布问卷
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/Public`,
            data:info,
        })
    },
    AddProjectQuestionnaireData:(info)=>{//添加项目里面的问卷
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/Bind`,
            data:info,
        })
    },
    AddRuleData:(info)=>{//添加随访规则
        return axios({
            method:"post",
            url:`${address}Project/Questionnaire/Rule/Add`,
            data:info,
        })
    },
    RuleGetPageListData:(info)=>{//随访规则分页
        return axios({
            method:"post",
            url:`${address}Project/Questionnaire/Rule/GetPageList`,
            data:info,
        })
    },
    EditRuleData:(info)=>{//修改随访规则
        return axios({
            method:"post",
            url:`${address}Project/Questionnaire/Rule/Edit`,
            data:info,
        })
    },
    StartUpRuleData:(info)=>{//启动随访规则
        return axios({
            method:"post",
            url:`${address}Project/Questionnaire/Rule/Start`,
            data:info,
        })
    },
    StopRuleData:(info)=>{//停止随访规则
        return axios({
            method:"post",
            url:`${address}Project/Questionnaire/Rule/Stop`,
            data:info,
        })
    },
    DeleteRuleData:(info)=>{//删除随访规则
        return axios({
            method:"post",
            url:`${address}Project/Questionnaire/Rule/Delete`,
            data:info,
        })
    },
    CollectedQuestionnaireData:(info)=>{
        return axios({
            method:"post",
            url:`${address}PatientCenter/Questionnaire/Collected/GetPageList`,
            data:info,
        })
    },
    CallBackQuestionnaireData:(info)=>{//新建问卷时候调用
        return axios({
            method:"post",
            url:`${address}CallBack/Prepare`,
            data:info
        })
    },
    ProjectDeleteData:(info)=>{//删除项目问卷绑定关系
        return axios({
            method:"post",
            url:`${address}QuestionnaireCenter/UnBind`,
            data:info
        })
    },
}

export default api
