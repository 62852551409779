<template>
    <div class="FollowUpRules">
        <el-link @click="FollowUpRules_dialog" :underline="false"><img
                src="../../assets/image/icon/gliansf.png" />关联随访规则</el-link>
        <div class="FollowUpRules_dialog">
            <el-dialog top="1vh" :visible.sync="dialogVisible" width="60%">
                <div slot="title" class="header-title">
                    <span class="title-name"></span>
                    <span class="title-age">关联随访规则</span>
                </div>
                <div class="FollowUpRules_div" style="height:720px;overflow:auto;">
                    <ul>
                        <li>
                            <span class="span1"></span>
                            <span class="span2">随访规则（组合）设置</span>
                        </li>
                        <!-- <li>
                             <span class="span_equally">机器人:</span>
                             <span style="width: 330px;">
                                 <el-select v-model="rule.RobotDefId" placeholder="请选择">
                                        <el-option
                                        v-for="(item,index) in ruleLIst"
                                        :key="index"
                                        :label="item.Name"
                                        :value="item.Id">
                                        </el-option>
                                    </el-select>
                            </span>
                        </li> -->
                        <li>
                            <span class="span_equally">规则名称:</span>
                            <span style="width: 330px;">
                                <el-input v-model="rule.Name" placeholder="请输入内容"></el-input>
                            </span>
                        </li>
                        <li>
                            <span class="span_equally">随访方式:</span>
                            <el-radio-group v-model="rule.Channel">
                                <el-radio label="00">AI语音</el-radio>
                                <el-radio label="01">短信</el-radio>
                                <el-radio label="02">人工</el-radio>
                            </el-radio-group>
                        </li>
                        <li>
                            <span class="span_equally">开始时间:</span>
                            <span style="width: 330px;">
                                <el-select v-model="rule.BaseTimeMode" placeholder="请选择" @change="BaseTimeModeChange">
                                    <el-option v-for="(item, index) in StartTime" :key="index" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </span>

                            <span v-show="'04' == rule.BaseTimeMode">
                                <span>选择规则:</span>
                                <span style="width: 180px;">
                                    <el-select v-model="rule.BaseTimeArgs" placeholder="请选择">
                                        <el-option v-for="(item, index) in SelectRule" :key="index" :label="item.Name"
                                            :value="item.Id">
                                        </el-option>
                                    </el-select>
                                </span>
                            </span>
                            <span v-show="'00' == rule.BaseTimeMode">
                                <span>选择日期:</span>
                                <span style="width: 180px;">
                                    <el-date-picker v-model="rule.BaseTimeArgs" type="date" value-format="yyyy-MM-dd"
                                        placeholder="选择日期">
                                    </el-date-picker>
                                </span>
                            </span>

                        </li>
                        <li>
                            <span class="span_equally">时间间隔:</span>
                            <span>每隔</span>
                            <span style="width: 282px;margin-right: 10px;">
                                <el-input v-model="rule.IntervalValue" @change="IntervalValueChange"></el-input>
                            </span>
                            <span style="width: 70px;">
                                <el-select v-model="rule.IntervalUnit" placeholder="">
                                    <el-option v-for="(item, index) in Days" :key="index" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </span>
                            <span>随访1次</span>
                            <p v-show="IntervalValue" style="margin-left: 200px;color:red;">请输入整数（不包括0）</p>
                        </li>
                        <li>
                            <span class="span_equally">本条规则执行次数:</span>
                            <span style="width: 330px;"><el-input :disabled="rule.ExeCount == '0'" @change="ExeCountChange"
                                    v-model="rule.ExeCount" placeholder="请输入内容"></el-input></span>
                            <span>次</span>
                            <el-checkbox v-model="rule.ExeCount" true-label="0" false-label="">不限</el-checkbox>
                            <p v-show="ExeCountFlag" style="margin-left: 200px;color:red;">请输入整数（不包括0）</p>
                        </li>
                        <li>
                            <div>
                                <p style="margin-bottom: 10px;">
                                    <span class="span_equally">是否临时终止条件:</span>
                                    <el-radio-group v-model="rule.HasInterrupt">
                                        <el-radio :label="true">是</el-radio>
                                        <el-radio :label="false">否</el-radio>
                                    </el-radio-group>
                                </p>
                                <div style="margin-bottom: 10px;" v-for="(m, index) in InterruptArgs" :key="index">
                                    <InterruptArgs @Edelcondition="Edelcondition" :item="m" :index="index"
                                        v-if="rule.HasInterrupt" @Eaddcondition="Eaddcondition"></InterruptArgs>
                                </div>
                            </div>
                            <div>
                                <p style="margin-bottom: 10px;">
                                    <span class="span_equally">是否不良事件告警:</span>
                                    <el-radio-group v-model="rule.HasAlarm">
                                        <el-radio :label="true">是</el-radio>
                                        <el-radio :label="false">否</el-radio>
                                    </el-radio-group>
                                </p>
                                <div style="margin-bottom: 10px;" v-for="(m, index) in AlarmArgs" :key="index">
                                    <condition @delcondition="delcondition" :item="m" :index="index" v-if="rule.HasAlarm"
                                        @addcondition="addcondition"></condition>
                                </div>
                            </div>
                        </li>
                        <div v-show="rule.HasAlarm">

                            <li style="display:flex;">
                                <span class="span_equally">告警方式:</span>
                                <span>
                                    <el-checkbox v-model="AlarmChannel1">短信</el-checkbox>
                                    <el-checkbox v-model="AlarmChannel2">系统消息</el-checkbox>
                                    <!-- <el-radio-group v-model="rule.AlarmChannel">
                                        <el-radio label="00">短信</el-radio>
                                        <el-radio label="01">系统消息</el-radio>
                                </el-radio-group> -->
                                </span>
                            </li>
                            <li style="display:flex;">
                                <span class="span_equally">告警对象:</span>
                                <span>
                                    <el-radio-group v-model="rule.AlarmTarget">
                                        <el-radio label="00">全部项目成员</el-radio>
                                        <el-radio label="01">项目PI</el-radio>
                                        <el-radio label="02">课题负责人</el-radio>
                                    </el-radio-group>
                                </span>
                            </li>
                            <li>
                                <span style="margin-left:200px;">
                                    <el-radio v-model="rule.AlarmTarget" label="03">责任医生</el-radio>
                                </span>
                                <span style="width: 630px;">
                                    <el-input v-model="rule.AlarmContent" placeholder="请输入内容"></el-input>
                                </span>
                            </li>
                        </div>
                    </ul>
                    <el-divider></el-divider>
                    <ul style="margin-bottom: 30px;" v-show="rule.Channel == '02'">
                        <li>
                            <span class="span1"></span>
                            <span class="span2">随访任务提醒规则设置</span>
                        </li>
                        <li>
                            <span class="span_equally">是否提醒:</span>
                            <el-radio-group v-model="rule.HasAlert" @change="HasAlertChange">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </li>
                        <div v-show="rule.HasAlert">
                            <li>
                                <span class="span_equally">提前:</span>
                                <span>
                                    <el-input v-model="rule.AlertDay" placeholder="请输入内容"></el-input>
                                </span>
                                <span>天提醒</span>
                            </li>
                            <li>
                                <span class="span_equally">提醒方式:</span>
                                <span>
                                    <!-- <el-checkbox-group v-model="rule.AlertChannel"> -->
                                    <el-checkbox v-model="AlertChannel1">短信</el-checkbox>
                                    <el-checkbox v-model="AlertChannel2">系统消息</el-checkbox>
                                    <!-- </el-checkbox-group> -->
                                    <!-- <el-radio-group v-model="rule.AlertChannel">
                                    <el-radio label="00">短信</el-radio>
                                    <el-radio label="01">系统消息</el-radio>
                                </el-radio-group> -->
                                </span>
                            </li>
                            <li>
                                <span class="span_equally">提醒对象:</span>
                                <span>
                                    <el-radio-group v-model="rule.AlertTarget">
                                        <el-radio label="00">全部项目成员</el-radio>
                                        <el-radio label="01">项目PI</el-radio>
                                        <el-radio label="02">课题负责人</el-radio>
                                    </el-radio-group>
                                </span>
                            </li>
                            <li>
                                <span style="margin-left:200px;">
                                    <el-radio v-model="rule.AlertTarget" label="03">责任医生</el-radio>
                                </span>
                                <span style="width: 630px;">
                                    <el-input v-model="rule.AlertContent" placeholder="请输入内容"></el-input>
                                </span>
                            </li>
                        </div>
                    </ul>
                    <div class="btn">
                        <button @click="dialogVisible = false">取消</button>
                        <button @click="preservation">保存</button>
                    </div>
                    <div>
                        <div>规则组</div>
                        <div style="margin-bottom: 10px;height:200px;">
                            <el-table :data="tableData" :header-cell-style="{ background: 'rgb(240, 247, 253)' }" border
                                style="width: 100%;height:200px;">
                                <el-table-column prop="index" label="序号" width="80">
                                </el-table-column>
                                <el-table-column prop="Code" label="规则编号" width="220">
                                </el-table-column>
                                <el-table-column label="随访方式" width="100">
                                    <template slot-scope="scope">
                                        {{ scope.row.Channel == '00' ? 'AI语音' : scope.row.Channel == '01' ? '短信' : scope.row.Channel == '02' ? '人工' : '' }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="开始时间模式" width="200">
                                    <template slot-scope="scope">
                                        {{ scope.row.BaseTimeMode == '00' ? '问卷上的某个日期'
                                            : scope.row.BaseTimeMode == '01' ? '出院日期'
                                                : scope.row.BaseTimeMode == '02' ? '就诊日期'
                                                    : scope.row.BaseTimeMode == '03' ? '出生日期'
                                                        : scope.row.BaseTimeMode == '04' ? '以规则终点为起点时间' : "" }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="address" label="随访间隔" width="90">
                                    <template slot-scope="scope">
                                        {{ scope.row.IntervalValue }}
                                        {{ scope.row.IntervalUnit == '00' ? '天'
                                            : scope.row.IntervalUnit == '01' ? '周'
                                                : scope.row.IntervalUnit == '02' ? '月'
                                                    : scope.row.IntervalUnit == '03' ? '季'
                                                        : scope.row.IntervalUnit == '04' ? '年' : ''
                                        }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="执行次数" width="100">
                                    <template slot-scope="scope">
                                        {{ scope.row.ExeCount == '0' ? '不限' : scope.row.ExeCount + '次' }}
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column
                            prop="zip"
                            label="不良事件警告"
                            width="120">
                            </el-table-column>
                            <el-table-column
                            prop="zip"
                            label="临时终止条件"
                            width="120">
                            </el-table-column> -->
                                <!-- <el-table-column
                            prop="zip"
                            label="警告方式"
                            width="120">
                            </el-table-column> -->
                                <el-table-column fixed="right" label="操作" width="230">
                                    <template slot-scope="scope">
                                        <el-link v-show="scope.row.Status == 2" :underline="false"
                                            @click="StartRule(scope.row)"><i class="el-icon-edit-outline"></i>启动</el-link>
                                        <el-link v-show="scope.row.Status == 0" :underline="false"
                                            @click="StopRule(scope.row)"><i class="el-icon-edit-outline"></i>停止</el-link>
                                        <el-link :underline="false" @click="EditQuestionnaire(scope.row)"><i
                                                class="el-icon-edit-outline"></i>修改</el-link>
                                        <el-link :underline="false" class="del" size="mini"
                                            @click="DeleteRule(scope.row)"><i class="el-icon-delete"></i>删除</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div>
                            <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows"
                                @pagination="HaveRuleList"></Pagination>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!-- 修改规则 -->
        <div class="FollowUpRules_dialog">
            <el-dialog @close="cancel" top="1vh" :visible.sync="modifydialogVisible" width="60%">
                <div slot="title" class="header-title">
                    <span class="title-name"></span>
                    <span class="title-age">关联随访规则</span>
                </div>
                <div class="FollowUpRules_div" style="height:720px;overflow:auto;">
                    <ul>
                        <li>
                            <span class="span1"></span>
                            <span class="span2">随访规则（组合）设置</span>
                        </li>
                        <li>
                            <span class="span_equally">规则名称:</span>
                            <span style="width: 330px;">
                                <el-input v-model="modifyrule.Name" placeholder="请输入内容"></el-input>
                            </span>
                        </li>
                        <li>
                            <span class="span_equally">随访方式:</span>
                            <el-radio-group v-model="modifyrule.Channel">
                                <el-radio label="00">AI语音</el-radio>
                                <el-radio label="01">短信</el-radio>
                                <el-radio label="02">人工</el-radio>
                            </el-radio-group>
                        </li>
                        <li>
                            <span class="span_equally">开始时间111:</span>
                            <span style="width: 330px;">
                                <el-select v-model="modifyrule.BaseTimeMode" placeholder="请选择" @change="BaseTimeModeChange">
                                    <el-option v-for="(item, index) in StartTime" :key="index" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </span>

                            <span v-show="'04' == modifyrule.BaseTimeMode">
                                <span>选择规则:</span>
                                <span style="width: 180px;">
                                    <el-select v-model="modifyrule.BaseTimeArgs" placeholder="请选择">
                                        <el-option v-for="(item, index) in SelectRule" :key="index" :label="item.Name"
                                            :value="item.Id">
                                        </el-option>
                                    </el-select>
                                </span>
                            </span>
                            <span
                                v-show="'00' == modifyrule.BaseTimeMode || '01' == modifyrule.BaseTimeMode || '02' == modifyrule.BaseTimeMode || '03' == modifyrule.BaseTimeMode">
                                <span>选择日期:</span>
                                <span style="width: 180px;">
                                    <el-date-picker v-model="modifyrule.BaseTimeArgs" type="date" value-format="yyyy-MM-dd"
                                        placeholder="选择日期">
                                    </el-date-picker>
                                </span>
                            </span>

                        </li>
                        <li>
                            <span class="span_equally">时间间隔:</span>
                            <span>每隔</span>
                            <span style="width: 282px;margin-right: 10px;">
                                <el-input v-model="modifyrule.IntervalValue" @change="IntervalValueChange"></el-input>
                            </span>
                            <span style="width: 70px;">
                                <el-select v-model="modifyrule.IntervalUnit" placeholder="">
                                    <el-option v-for="(item, index) in Days" :key="index" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </span>
                            <span>随访1次</span>
                            <p v-show="IntervalValue" style="margin-left: 200px;color:red;">请输入整数（不包括0）</p>
                        </li>
                        <li>
                            <span class="span_equally">本条规则执行次数:</span>
                            <span style="width: 330px;"><el-input :disabled="modifyrule.ExeCount == '0'"
                                    @change="ExeCountChange" v-model="modifyrule.ExeCount"
                                    placeholder="请输入内容"></el-input></span>
                            <span>次</span>
                            <el-checkbox :checked="modifyrule.ExeCount == 0" v-model="modifyrule.ExeCount" true-label="0"
                                false-label="">不限</el-checkbox>
                            <p v-show="ExeCountFlag" style="margin-left: 200px;color:red;">请输入整数（不包括0）</p>
                        </li>
                        <li>
                            <div>
                                <p style="margin-bottom: 10px;">
                                    <span class="span_equally">是否不良事件告警:</span>
                                    <el-radio-group v-model="modifyrule.HasAlarm">
                                        <el-radio :label="true">是</el-radio>
                                        <el-radio :label="false">否</el-radio>
                                    </el-radio-group>
                                </p>
                                <div style="margin-bottom: 10px;" v-for="(m, index) in EAlarmArgs" :key="index">
                                    <condition @delcondition="delcondition" @addcondition="addcondition" :item="m"
                                        :index="index" v-if="modifyrule.HasAlarm"></condition>
                                </div>
                            </div>
                            <div>
                                <p style="margin-bottom: 10px;">
                                    <span class="span_equally">是否临时终止条件:</span>
                                    <el-radio-group v-model="modifyrule.HasInterrupt">
                                        <el-radio :label="true">是</el-radio>
                                        <el-radio :label="false">否</el-radio>
                                    </el-radio-group>
                                </p>
                                <div style="margin-bottom: 10px;" v-for="(m, index) in EInterruptArgs" :key="index">
                                    <InterruptArgs @Edelcondition="Edelcondition" @Eaddcondition="Eaddcondition" :item="m"
                                        :index="index" v-if="modifyrule.HasInterrupt"></InterruptArgs>
                                </div>
                            </div>
                        </li>
                        <li style="display:flex;">
                            <span class="span_equally">告警方式:</span>
                            <span>
                                <!-- <el-checkbox-group v-model="modifyrule.AlarmChannel"> -->
                                <el-checkbox v-model="AlarmChannel1">短信</el-checkbox>
                                <el-checkbox v-model="AlarmChannel2">系统消息</el-checkbox>
                                <!-- </el-checkbox-group> -->
                                <!-- <el-radio-group v-model="modifyrule.AlarmChannel">
                                    <el-radio label="00">短信</el-radio>
                                    <el-radio label="01">系统消息</el-radio>
                                </el-radio-group> -->
                            </span>
                        </li>
                        <li style="display:flex;">
                            <span class="span_equally">告警对象:</span>
                            <span>
                                <el-radio-group v-model="modifyrule.AlarmTarget">
                                    <el-radio label="00">全部项目成员</el-radio>
                                    <el-radio label="01">项目PI</el-radio>
                                    <el-radio label="02">课题负责人</el-radio>
                                </el-radio-group>
                            </span>
                        </li>
                        <li>
                            <span style="margin-left:200px;">
                                <el-radio v-model="modifyrule.AlarmTarget" label="03">责任医生</el-radio>
                            </span>
                            <span style="width: 630px;">
                                <el-input v-model="modifyrule.AlarmContent" placeholder="请输入内容"></el-input>
                            </span>
                        </li>
                    </ul>
                    <el-divider></el-divider>
                    <ul style="margin-bottom: 30px;">
                        <li>
                            <span class="span1"></span>
                            <span class="span2">随访任务提醒规则设置</span>
                        </li>
                        <li>
                            <span class="span_equally">是否提醒:</span>
                            <el-radio-group v-model="modifyrule.HasAlert" @change="HasAlertChange">
                                <el-radio :label="true">是</el-radio>
                                <el-radio :label="false">否</el-radio>
                            </el-radio-group>
                        </li>
                        <div v-show="modifyrule.HasAlert">
                            <li>
                                <span class="span_equally">提前:</span>
                                <span>
                                    <el-input v-model="modifyrule.AlertDay" placeholder="请输入内容"></el-input>
                                </span>
                                <span>天提醒</span>
                            </li>
                            <li>
                                <span class="span_equally">提醒方式:</span>
                                <span>
                                    <!-- <el-checkbox-group v-model="modifyrule.AlarmChannel"> -->
                                    <el-checkbox v-model="AlertChannel1">短信</el-checkbox>
                                    <el-checkbox v-model="AlertChannel2">系统消息</el-checkbox>
                                    <!-- </el-checkbox-group> -->
                                    <!-- <el-radio-group v-model="modifyrule.AlertChannel">
                                    <el-radio label="00">短信</el-radio>
                                    <el-radio label="01">系统消息</el-radio>
                                </el-radio-group> -->
                                </span>
                            </li>
                            <li>
                                <span class="span_equally">提醒对象:</span>
                                <span>
                                    <el-radio-group v-model="modifyrule.AlertTarget">
                                        <el-radio label="00">全部项目成员</el-radio>
                                        <el-radio label="01">项目PI</el-radio>
                                        <el-radio label="02">课题负责人</el-radio>
                                    </el-radio-group>
                                </span>
                            </li>
                            <li>
                                <span style="margin-left:200px;">
                                    <el-radio v-model="modifyrule.AlertTarget" label="03">责任医生</el-radio>
                                </span>
                                <span style="width: 630px;">
                                    <el-input v-model="modifyrule.AlertContent" placeholder="请输入内容"></el-input>
                                </span>
                            </li>
                        </div>
                    </ul>
                    <div class="btn">
                        <button @click="cancel">取消</button>
                        <button @click="modify">修改</button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import condition from "../task/condition.vue"
import InterruptArgs from "../task/InterruptArgs.vue"
import ProjectManagement from "../../api/ProjectManagement"
import Pagination from "../../components/Pagination/index.vue"
export default {
    data() {
        return {
            checked: false,
            AlarmChannel1: false,
            AlarmChannel2: false,
            AlertChannel1: false,
            AlertChannel2: false,
            total: 0,
            listQuery: {
                Key: "",
                page: 1,
                rows: 5,
            },
            rule: {
                //   RobotDefId:'',
                Name: "",
                ProjectId: this.$route.query.Id,
                ProjectQuestionnaireId: this.item.QuestionnaireId,
                Channel: "00",//随访方式
                BaseTimeMode: "",
                BaseTimeArgs: '',
                IntervalValue: "",
                IntervalUnit: "00",
                ExeCount: "",
                HasAlarm: false,
                AlarmArgs: [],
                HasInterrupt: false,
                InterruptArgs: [],
                AlarmChannel: '',//警告方式
                AlarmTarget: "",//告警对象
                AlarmContent: "",//告警说明
                AlertChannel: "",
                AlertTarget: "",
                AlertContent: "",
                HasAlert: true,//是否提醒
                AlertDay: "",
            },
            modifyrule: {
                RobotDefId: '',
                Name: "",
                ProjectId: this.$route.query.Id,
                ProjectQuestionnaireId: this.item.QuestionnaireId,
                Channel: "00",//随访方式
                BaseTimeMode: "",
                BaseTimeArgs: '',
                IntervalValue: "",
                IntervalUnit: "00",
                ExeCount: "",
                HasAlarm: false,
                AlarmArgs: [],
                HasInterrupt: false,
                InterruptArgs: [],
                AlarmChannel: "",//警告方式
                AlarmTarget: "",//告警对象
                AlarmContent: "",//告警说明
                AlertChannel: "",
                AlertTarget: "",
                AlertContent: "",
                HasAlert: true,//是否提醒
                AlertDay: "",
            },
            tableData: [],
            dialogVisible: false,
            modifydialogVisible: false,
            Days: [{
                value: '00',
                label: '天'
            }, {
                value: '01',
                label: '周'
            }, {
                value: '02',
                label: '月'
            }, {
                value: '03',
                label: '季'
            }, {
                value: '04',
                label: '年'
            },],
            StartTime: [{
                value: '00',
                label: '问卷上的某个日期'
            }, {
                value: '01',
                label: '出院日期'
            }, {
                value: '02',
                label: '就诊日期'
            },
            {
                value: '03',
                label: '手术日期'
            },
            {
                value: '04',
                label: '以规则终点为起点时间'
            },
            ],
            SelectRule: [],
            ExeCountFlag: false,
            IntervalValue: false,
            disabled: false,
            type: 1,
            AlarmArgs: [
                {
                    id: this.guid(),
                    relation: '',
                    qid: this.item.QuestionnaireId,
                    QidName: this.item.questionnaireName,
                    field: '',//题目id
                    qtype: '',
                    operator: '=',
                    value: "",//值
                },
            ],
            InterruptArgs: [
                {
                    relation: '',
                    qid: this.item.QuestionnaireId,
                    QidName: this.item.questionnaireName,
                    field: "",//题目id
                    qtype: '',
                    operator: '=',
                    value: "",//值
                },
            ],
            EAlarmArgs: [],
            EInterruptArgs: [],
            // ruleLIst:[],   
        }
    },
    components: {
        condition,
        InterruptArgs,
        Pagination,
    },
    props: ['item'],
    created() {
    },
    methods: {
        // GetRobotList(){
        //     ProjectManagement.GetRobotListData(this.item.QuestionnaireId).then(res=>{
        //         if(res.data.Status==1){
        //             this.ruleLIst=res.data.Data
        //         }
        //          console.log(res,"机器人");
        //     })
        // },
        cancel() {
            this.AlarmChannel1 = false
            this.AlarmChannel2 = false
            this.AlertChannel1 = false
            this.AlertChannel2 = false
            this.type = 1
            this.modifydialogVisible = false
            console.log(this.type);
        },
        Edelcondition(data) {
            if (this.type == 1) {
                this.InterruptArgs = this.InterruptArgs.filter(row => {
                    return row.id != data
                })
            } else {
                this.EInterruptArgs = this.EInterruptArgs.filter(row => {
                    return row.id != data
                })
            }
        },
        Eaddcondition() {
            if (this.type == 1) {
                this.InterruptArgs.push(
                    {
                        id: this.guid(),
                        relation: 'OR',
                        qid: this.item.Id,
                        QidName: this.item.questionnaireName,
                        field: "",//题目id
                        operator: '',
                        value: "",//值
                        qtype: '',
                    },
                )
            } else {
                this.EInterruptArgs.push(
                    {
                        id: this.guid(),
                        relation: 'OR',
                        qid: this.item.Id,
                        QidName: this.item.questionnaireName,
                        field: "",//题目id
                        operator: '',
                        value: "",//值
                        qtype: '',
                    },
                )
            }
        },
        delcondition(data) {
            if (this.type == 1) {
                this.AlarmArgs = this.AlarmArgs.filter(row => {
                    return row.id != data
                })
            } else {
                this.EAlarmArgs = this.EAlarmArgs.filter(row => {
                    return row.id != data
                })
            }
        },
        addcondition() {
            if (this.type == 1) {
                this.AlarmArgs.push(
                    {
                        id: this.guid(),
                        relation: 'OR',
                        qid: this.item.Id,
                        QidName: this.item.questionnaireName,
                        field: "",//题目id
                        operator: '',
                        value: "",//值
                        qtype: '',
                    },
                )
            } else {
                this.EAlarmArgs.push(
                    {
                        id: this.guid(),
                        relation: 'OR',
                        qid: this.item.Id,
                        QidName: this.item.questionnaireName,
                        field: "",//题目id
                        operator: '',
                        value: "",//值
                        qtype: '',
                    },
                )
            }
        },
        guid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        //启动
        StartRule(row) {
            const parameter = {
                Id: row.Id
            }
            this.$confirm('此操作将启动规则, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                ProjectManagement.StartRuleData(parameter).then(res => {
                    console.log(res);
                    if (res.data.Status == 1) {
                        row.Status = 0
                        this.$message({
                            type: 'success',
                            message: '启动成功!'
                        });
                    } else {
                        alert(res.data.Message)
                    }
                })
            })
        },
        //停止
        StopRule(row) {
            const parameter = {
                Id: row.Id
            }
            this.$confirm('此操作将停止规则, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                ProjectManagement.StopRuleData(parameter).then(res => {
                    if (res.data.Status == 1) {
                        row.Status = 2
                        this.$message({
                            type: 'success',
                            message: '停止成功!'
                        });
                    } else {
                        alert(res.data.Message)
                    }
                })
            })
        },
        //修改规则
        modify() {
            let AlarmArgslistflag = null
            if (this.EAlarmArgs.length == 0 && this.modifyrule.HasAlarm) {
                alert("不良事件告警:题目,条件,值,不能为空")
                return
            }
            AlarmArgslistflag = this.EAlarmArgs.some((item) => {
                return item.value == "" || item.field == ""
            })
            if (AlarmArgslistflag) {
                alert("不良事件告警:题目,条件,值,不能为空")
                return
            }
            let InterruptArgslistflag = null
            if (this.EInterruptArgs.length == 0 && this.modifyrule.HasInterrupt) {
                alert("临时终止条件:题目,条件,值,不能为空")
                return
            }
            InterruptArgslistflag = this.EInterruptArgs.some((item) => {
                return item.value == "" || item.field == ""
            })
            if (InterruptArgslistflag) {
                alert("临时终止条件:题目,条件,值,不能为空")
                return
            }
            if (this.modifyrule.HasAlert) {
                if (this.modifyrule.AlertDay == "" || this.modifyrule.AlertChannel == "" || this.modifyrule.AlertTarget == "" || this.modifyrule.AlertContent == "") {
                    return alert("是否提醒选择了是,则必须填写完下面内容")
                }
            }
            if (this.modifyrule.Name == "") {
                return alert("规则名称不能为空")
            }
            if (this.modifyrule.Channel == "") {
                return alert("随访方式不能为空")
            }
            if (this.modifyrule.BaseTimeMode == "" || this.modifyrule.BaseTimeArgs == "") {
                return alert("开始时间不能为空")
            }
            if (this.modifyrule.IntervalValue == "") {
                return alert("时间间隔不能为空")
            }
            if (this.modifyrule.AlarmChannel == "") {
                return alert("告警方式不能为空")
            }
            if (this.modifyrule.AlarmTarget == "" || this.modifyrule.AlarmContent == "") {
                return alert("告警对象不能为空")
            }
            if (this.rule.AlertContent == '') {
                return alert("告警对象内容必填")
            }
            if (this.modifyrule.HasAlarm) {
                this.modifyrule.AlarmArgs = JSON.stringify(this.EAlarmArgs)
            } else {
                this.modifyrule.AlarmArgs = null
            }
            if (this.modifyrule.HasInterrupt) {
                this.modifyrule.InterruptArgs = JSON.stringify(this.EInterruptArgs)
            } else {
                this.modifyrule.InterruptArgs = null
            }
            ProjectManagement.EditRuleData(this.modifyrule).then(res => {
                console.log(res, "修改");
                if (res.data.Status == 1) {
                    this.tableData = this.tableData.map(item => {
                        if (item.Id == this.modifyrule.Id) {
                            item = this.modifyrule
                        }
                        return item
                    })
                    this.modifydialogVisible = false
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                } else {
                    alert(res.data.Message)
                }
            })
        },
        HasAlertChange(e) {
            if (!e) {
                if (this.type == 1) {
                    this.rule.AlertDay = ""
                    this.rule.AlertChannel = ""
                    this.rule.AlertTarget = ""
                    this.rule.AlertContent = ""
                } else {
                    this.modifyrule.AlertDay = ""
                    this.modifyrule.AlertChannel = ""
                    this.modifyrule.AlertTarget = ""
                    this.modifyrule.AlertContent = ""
                }
            }
        },
        async HaveRuleList() {
            const parameter = {
                Key: "",
                page: this.listQuery.page,
                rows: this.listQuery.rows,
                ProjectQuestionnaireId: this.item.Id,
                ProjectId: this.$route.query.Id,
            }
            ProjectManagement.HaveRuleListData(parameter).then(res => {
                console.log(res, "有分页列表");
                if (res.data.Status == 1) {
                    this.total = res.data.Data.total
                    this.tableData = res.data.Data.rows
                } else {
                    alert(res.data.Message)
                }
            })
        },
        IntervalValueChange(e) {
            let text = /^[1-9]\d*$/
            if (text.test(e)) {
                this.IntervalValue = false
            } else {
                this.IntervalValue = true
            }
        },
        ExeCountChange(e) {
            let text = /^[1-9]\d*$/
            if (text.test(e)) {
                this.ExeCountFlag = false
            } else {
                this.ExeCountFlag = true
            }
        },
        async NothingRuleList() {
            const parameter = {
                ProjectQuestionnaireId: this.item.QuestionnaireId,
                ProjectId: this.$route.query.Id,
            }
            ProjectManagement.NothingRuleListData(parameter).then(res => {
                if (res.data.Status == 1) {
                    this.SelectRule = res.data.Data
                } else {
                    alert(res.data.Message)
                }
                console.log(res, "规则无分页列表");
            })
        },
        //保存
        preservation() {
            // alert(this.rule.AlarmChannel)
            // console.log(this.AlertChannel1,this.AlertChannel1,"%%%%%%%%%");
            if (this.AlarmChannel1) {
                this.rule.AlarmChannel = '00'
            }
            if (this.AlarmChannel2) {
                this.rule.AlarmChannel = '01'
            }
            if (this.AlarmChannel1 && this.AlarmChannel2) {
                this.rule.AlarmChannel = '00|01'
            }
            if (this.AlertChannel1) {
                this.rule.AlertChannel = '00'
            }
            if (this.AlertChannel2) {
                this.rule.AlertChannel = '01'
            }
            if (this.AlertChannel1 && this.AlertChannel2) {
                this.rule.AlertChannel = '00|01'
            }
            let AlarmArgslistflag = null
            if (this.rule.HasAlarm) {
                AlarmArgslistflag = this.AlarmArgs.some((item) => {
                    return item.value == "" || item.field == ""
                })
                if (AlarmArgslistflag) {
                    return alert("不良事件告警:题目,条件,值,不能为空")
                }
                if (this.rule.AlarmChannel == "") {
                    return alert("告警方式不能为空")
                }
                if (this.rule.AlarmTarget == "") {
                    return alert("告警对象不能为空")
                }
                if (this.rule.AlarmContent == "") {
                    return alert("责任医生后面的内容不能为空")
                }
            }
            let InterruptArgslistflag = null
            if (this.rule.HasInterrupt) {
                InterruptArgslistflag = this.InterruptArgs.some((item) => {
                    return item.value == "" || item.field == ""
                })
                if (InterruptArgslistflag) {
                    alert("临时终止条件:题目,条件,值,不能为空")
                    return
                }
            }
            if (this.rule.Channel == '00' || this.rule.Channel == '01') {
                this.rule.HasAlert = false
            }
            if (this.rule.HasAlert) {
                if (this.rule.AlertDay == "" || this.rule.AlertChannel == "" || this.rule.AlertTarget == "" || this.rule.AlertContent == "") {
                    return alert("是否提醒选择了是,则必须填写完下面所有内容")
                }
            }
            if (this.rule.HasAlarm) {
                this.rule.AlarmArgs = JSON.stringify(this.AlarmArgs)
            } else {
                this.rule.AlarmArgs = null
            }
            if (this.rule.HasInterrupt) {
                this.rule.InterruptArgs = JSON.stringify(this.InterruptArgs)
            } else {
                this.rule.InterruptArgs = null
            }
            console.log(this.rule);
            ProjectManagement.AddRuleData(this.rule).then(res => {
                if (res.data.Status == 1) {
                    this.tableData.push(res.data.Data)
                    this.dialogVisible = false
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    });
                } else {
                    alert(res.data.Message)
                }
                console.log(res);
            })
        },
        //打开弹框
        FollowUpRules_dialog() {
            this.AlarmChannel1 = false,
                this.AlarmChannel2 = false,
                this.AlertChannel1 = false,
                this.AlertChannel2 = false,
                this.rule = {
                    Name: "",
                    ProjectId: this.$route.query.Id,
                    ProjectQuestionnaireId: this.item.Id,
                    Channel: "02",//随访方式
                    BaseTimeMode: "",
                    BaseTimeArgs: '',
                    IntervalValue: "",
                    IntervalUnit: "00",
                    ExeCount: "",
                    HasAlarm: false,
                    AlarmArgs: [],
                    HasInterrupt: false,
                    InterruptArgs: [],
                    AlarmChannel: '',//警告方式
                    AlarmTarget: "",//告警对象
                    AlarmContent: "",//告警说明
                    AlertChannel: "",
                    AlertTarget: "",
                    AlertContent: "",
                    HasAlert: true,//是否提醒
                    AlertDay: "",
                },
                this.type = 1
            this.AlarmArgs = [
                {
                    id: this.guid(),
                    relation: '',
                    qid: this.item.Id,
                    QidName: this.item.questionnaireName,
                    field: '',//题目id
                    qtype: '',
                    operator: '=',
                    value: "",//值
                },
            ],
                this.InterruptArgs = [
                    {
                        relation: '',
                        qid: this.item.Id,
                        QidName: this.item.questionnaireName,
                        field: "",//题目id
                        qtype: '',
                        operator: '=',
                        value: "",//值
                    },
                ],
                this.ExeCountFlag = false,
                this.IntervalValue = false,
                this.disabled = false,
                // this.GetRobotList()
                this.HaveRuleList()
            this.NothingRuleList()
            this.dialogVisible = true
        },
        BaseTimeModeChange() {
            this.rule.BaseTimeArgs = ""
        },
        EditQuestionnaire(row) {
            let AlarmChannel = row.AlarmChannel.split('|')
            let AlertChannel = row.AlertChannel.split('|')
            console.log(AlarmChannel, AlertChannel);
            if (AlarmChannel[0]) {
                AlarmChannel[0] = true
            }
            if (AlarmChannel[1]) {
                AlarmChannel[1] = true
            }
            if (AlertChannel[0]) {
                AlertChannel[0] = true
            }
            if (AlertChannel[1]) {
                AlertChannel[1] = true
            }
            this.AlarmChannel1 = AlarmChannel[0]
            this.AlarmChannel2 = AlarmChannel[1]
            this.AlertChannel1 = AlertChannel[0]
            this.AlertChannel2 = AlertChannel[1]
            this.type = 2
            this.modifyrule = row
            this.EAlarmArgs = JSON.parse(row.AlarmArgs)
            this.EInterruptArgs = JSON.parse(row.InterruptArgs)
            this.modifydialogVisible = true
        },
        //删除规则
        DeleteRule(row) {
            const parameter = []
            parameter.push(row.Id)
            this.$confirm('此操作将永久删除规则, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                ProjectManagement.DeleteRuleData(parameter).then(res => {
                    console.log(res);
                    if (res.data.Status == 1) {
                        this.tableData = this.tableData.filter(item => {
                            return item.Id != row.Id
                        })
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        alert(res.data.Message)
                    }
                })
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.p1 {
    display: inline-block;
}

span {
    text-align: right;
    display: inline-block;
    margin-right: 20px;
}

.FollowUpRules {
    display: inline-block;

    .FollowUpRules_dialog {
        ::v-deep .el-dialog__body {
            text-align: left;
        }

        .FollowUpRules_div {
            ul {
                li {
                    //    height: 100px;
                    margin-bottom: 10px;

                    ::v-deep .el-date-editor {
                        width: 100%;
                    }

                    ::v-deep .el-input__icon {
                        line-height: 30px;
                    }

                    ::v-deep .el-select {
                        width: 100%;
                    }

                    .span1 {
                        width: 8px;
                        height: 16px;
                        background: #3388FF;
                        vertical-align: middle;
                        margin-left: 120px;
                    }

                    .span2 {
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                        vertical-align: middle;
                    }

                    .span_equally {
                        width: 180px;
                    }
                }
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    ::v-deep .el-dialog {
        width: 90% !important;
    }
}
</style>