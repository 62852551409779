<template>
  <div class="main">
    <div class="box">
      <div class="questionnaire-content">
        <div class="header">
          <div class="header-left">
            <span>关键字:</span>
            <el-input placeholder="请输入关键字搜索"  v-model="keyword"></el-input>
            <el-button icon="el-icon-search" @click="search">查询</el-button>
            <!-- <el-button icon="el-icon-plus" @click="AddProject" v-if="admin!='00000000-0000-0000-0000-000000000000'">加入项目</el-button> -->
          </div>
          <div class="header-right">
            <!-- <el-button @click="AddQuestionnaire">+新建问卷</el-button> -->
            <!-- <div class="bg-right" v-show="!isMenu">
              <div style="width: 69px;height: 34px;" @click="isMenu = true"></div>
              <div style="width: 69px;height: 34px" @click="isMenu = false"></div>
            </div>
            <div class="bg-left" v-show="isMenu">
              <div style="width: 69px;height: 34px;" @click="isMenu = true"></div>
              <div style="width: 69px;height: 34px" @click="isMenu = false"></div>
            </div> -->
          </div>
        </div>
        <div class="content">
          <el-table
            ref="multipleTable"
            :data="ProjectQuestionnaireTable"
            tooltip-effect="dark"
            style="width: 100%;margin-bottom: 8px;"
            height="450px"
            border
            :header-cell-style="{background: 'rgb(240, 247, 253)'}"
          >
            <el-table-column type="selection" width="45" align="center">
            </el-table-column>
            <el-table-column type="index" width="60" align="center" label="序号">
            </el-table-column>
            <el-table-column
              prop="Name"
              label="问卷名称"
              min-width="80"
              align="center"
              :sortable="true"
              :sort-method="sortByQuestionnaireName"
            >
            </el-table-column>
            <el-table-column
              prop="CollectedQty"
              label="已收集问卷数"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="是否发布"
              width="150"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.IsPublic==true?"已发布":"未发布"}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="OwnerName"
              label="问卷创建人"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="CreateTime"
              label="创建时间"
              width="180"
              align="center"
              :sortable="true"
            >
            </el-table-column>
            <el-table-column label="操作" width="600" align="center">
              <template slot-scope="scope">
                <div>
                <el-link
                  :underline="false"
                  @click="SeeQuestionnaire(scope.row)"
                ><img src="../../assets/image/icon/search.png" alt="" style="vertical-align: middle">查看</el-link
                >
                <el-link
                v-if="admin!='00000000-0000-0000-0000-000000000000' && RoleType== 'D'"
                  :underline="false"
                  @click="EditQuestionnaire(scope.row)"
                ><i class="el-icon-edit-outline"></i>修改</el-link>
                <el-link
                v-if="admin!='00000000-0000-0000-0000-000000000000' && RoleType== 'D'"
                  :underline="false"
                  class="del"
                  size="mini"
                  @click="DeleteQuestionnaire(scope.row)"
                ><i class="el-icon-delete"></i>解绑</el-link
                >
                <el-link
                v-if="admin!='00000000-0000-0000-0000-000000000000' && RoleType== 'D'"
                  :underline="false"
                  @click="showPublish(scope.row)"
                ><i class="el-icon-video-pause"></i>{{scope.row.IsPublic==false?"确定发布":"取消发布"}}</el-link
                >
                <!-- <el-link
                  :underline="false"
                ><i class="el-icon-setting"></i>设置模板</el-link
                > -->
                <FollowUpRules v-if="admin!='00000000-0000-0000-0000-000000000000' && RoleType== 'D'" :item="scope.row"></FollowUpRules>
                <!-- <el-link
                   @click="FollowUpRules_dialog(scope.row)"
                  :underline="false"
                ><img  src="../../assets/image/icon/gliansf.png"/>关联随访规则</el-link
                 > -->
                </div>
                <!-- <el-link
                   @click="FollowUpRules(scope.row)"
                  :underline="false"
                ><img  src="../../assets/image/icon/gliansf.png"/>关联随访规则</el-link
                > -->
              </template>
            </el-table-column>
          </el-table>
           <Pagination
              :total="total"
              :page.sync="listQuery.page"
	            :limit.sync="listQuery.rows"
               @pagination="ProjectGetPageList"
        ></Pagination>
        </div>
      </div>
    </div>
        <div class="addQuestionnaire">
          <el-dialog
            custom-class="addQuestionnaireVisible"
            :visible.sync="addQuestionnaireVisible"
            :fullscreen="true"
          >
            <iframe
              frameborder="0"
              name="myFrame"
              id="myFrame"
              scrolling="yes"
              width="100%"
              height="1080px"
              :src="mUrl"
            ></iframe>
          </el-dialog>
        </div>
      <div>
        <el-dialog
        :visible.sync="QuestionnaireDialog"
        width="30%">
        <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">加入项目</span>
        </div>
         <div style="margin-bottom: 20px;" class="div_select">
         <el-select v-model="value" placeholder="请选择" multiple  value-key="Id">
            <el-option
              v-for="(item,index) in QuestionnaireTable"
              :key="index"
              :label="item.questionnaireName"
              :value="item">
            </el-option>
          </el-select>
         </div>
          <div class="btn">
            <button @click="QuestionnaireDialog = false">取消</button>
            <button type="primary" @click="AddProjectQuestionnaire">确定</button>
          </div>
        </el-dialog>
      </div>
        <!-- <FollowUpRules></FollowUpRules> -->

    </div>
</template>

<script>
import questionnaire from "../../api/questionnaire"
import FollowUpRules from "../task/FollowUpRules.vue"
import Pagination from "../Pagination/index.vue"
// import derive from "../../api/derive"
import {local} from "../../utils/storage"
export default {
  name: "questionnaire",
  data() {
    return {
     admin: local.get("UserId"),
     RoleType:local.get('RoleType'),
      value:"",
      dialogVisible: false, // 关联随访规则弹框
      isMenu: false,
      QuestionnaireTable: [], // 问卷列表数据
      ProjectQuestionnaireTable:[],//项目下的问卷列表
      mUrl:"",//问卷地址
      addQuestionnaireVisible: false,
      //配置
      mouseNum: -1,
      QuestionnaireDialog:false,//问卷列表弹框
      list:[],//储存问卷列表
      total:0,
      keyword:'',
      listQuery: {
        page: 1,
        rows: 10,
      },
    }
  },
  created() {
    // this.GetPageList()
    this.ProjectGetPageList()
  },
  components:{
    FollowUpRules,
    Pagination
  },
  methods: {
    sortByQuestionnaireName(obj1,obj2){
        if([obj1.Name,obj2.Name][0]==[obj1.Name,obj2.Name].sort((a,b)=>a.localeCompare(b,"zh",{sensitivity: 'accent'}))[0]){
          return -1
        }else{
          return 1
        }
      },
    //查询search
    search(){
      questionnaire.ProjectGetPageListData({Key: this.keyword, page: this.listQuery.page, rows: this.listQuery.rows,ProjectId:this.$route.query.Id}).then((res)=>{
        if(res.data.Status ==1){
          this.ProjectQuestionnaireTable = [];
          this.ProjectQuestionnaireTable = res.data.Data.rows;
          this.total = res.data.Data.total;
        }
      })
    },
    showPublish(row){
      console.log(row,'orwrw')
      let parameter={}
      let issueState=true
      if(row.IsPublic==false){
          issueState=true
          parameter={
          id:row.Id,
          issueState:issueState,
        }
        // QuestionnaireId
      }else{
          issueState=false
          parameter={
          id:row.Id,
          issueState:issueState,
        }
      }
      console.log(parameter,'parameterparameter')
       questionnaire.PublicQuestionnaireData(parameter).then(res=>{
              console.log(res);
            if(res.data.Status==1){
                    if(issueState){
                          this.$message({
                            message: '发布成功',
                            type: 'success'
                          });
                      }else{
                           this.$message({
                            message: '取消发布',
                            type: 'success'
                          });
                      }
               this.ProjectQuestionnaireTable=this.ProjectQuestionnaireTable.map(item=>{
                      if(item.Id==row.Id){
                          item.IsPublic=issueState
                      }
                      return item
               })
            }else{
               alert(res.data.Message)
            }
        })
    },
    // FollowUpRules_dialog(){},
    //项目下的问卷列表
    ProjectGetPageList(){
            const parameter={
              ProjectId:[this.$route.query.Id],
              Key:"",
              page:this.listQuery.page,
              rows:this.listQuery.rows
            }
            questionnaire.ProjectGetPageListData(parameter).then(res=>{
              console.log(res,"项目下的问卷列表");
                 if(res.data.Status==1){
                      this.total=res.data.Data.total
                      this.ProjectQuestionnaireTable=res.data.Data.rows
                 }else{
                    alert(res.data.Message)
                 }
            })
    },
    //确认问卷加入项目
    AddProjectQuestionnaire(){
        this.list=this.value.map(row=>{
             return {
               ProjectId:this.$route.query.Id,
               QuestionnaireId:row.QuestionnaireId
             }
        })
        console.log(this.list);
        questionnaire.AddProjectQuestionnaireData(this.list).then(res=>{
                 if(res.data.Status==1){
                    this.QuestionnaireDialog=false
                    this.ProjectGetPageList()
                 }else{
                    alert(res.data.Message)
                 }
                console.log(res);
        })
    },
    //打开加入项目
    AddProject(){
        this.value=""
        this.GetPageList()
        this.QuestionnaireDialog=true
    },
    //删除问卷
    DeleteQuestionnaire(row){
      let list=[]
      list.push(row.Id)
        this.$confirm('此操作将永久删除该项目问卷绑定关系, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          questionnaire.ProjectDeleteData(list).then(res=>{
              if(res.data.Status==1){
                this.ProjectQuestionnaireTable=this.ProjectQuestionnaireTable.filter(item=>{
                    return item.Id!=row.Id
                })
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
              }else{
                alert(res.data.Message)
              }
            console.log(res);
          })
        })
    },
    //查看问卷
    SeeQuestionnaire(row){
      this.mUrl=""
      this.addQuestionnaireVisible=true
          questionnaire.SeeQuestionnaireData().then(res=>{
              if(res.data.Status==1){
                // this.mUrl=`${res.data.Data.Url}/#/Preview?qid=${row.Id}&access_token=${res.data.Data.access_token}&refresh_token=${res.data.Data.refresh_token}&type=3`
                console.log(res.data.Data.Url);
                this.mUrl=`${res.data.Data.Url}/#/PcIframe?qid=${row.Id}&access_token=${res.data.Data.access_token}&refresh_token=${res.data.Data.refresh_token}&type=3`
              }else{
                    alert(res.data.Message)
              }
          })
    },
    // 修改问卷
    EditQuestionnaire(row){
          //  this.mUrl=""
           this.addQuestionnaireVisible=true
          questionnaire.EditQuestionnaireData().then(res=>{
            console.log(res.data.Data.Url,"编辑问卷");
              if(res.data.Status==1){
                this.mUrl=`${res.data.Data.Url}/#/PcIframe?qid=${row.Id}&access_token=${res.data.Data.access_token}&refresh_token=${res.data.Data.refresh_token}&type=2`
              }else{
                    alert(res.data.Message)
              }
          })
    },
    // //新增问卷
    // AddQuestionnaire(){
    //     this.addQuestionnaireVisible=true
    //     questionnaire.AddQuestionnaireData().then(res=>{
    //       if(res.data.Status==1){
    //          this.mUrl=`${res.data.Data.Url}/#/PcIframe?UserId=${res.data.Data.UserId}&OrganizationId=${res.data.Data.OrganizationId}&access_token=${res.data.Data.access_token}&refresh_token=${res.data.Data.refresh_token}&type=1`
    //       }else{
    //             alert(res.data.Message)
    //       }
    //       console.log(res,"新建问卷");
    //     })
    // },
    //问卷列表无分页
    GetPageList(){
      const parameter={
        ProjectId:"list",
        CurrentProjectId:this.$route.query.Id,
      }
      questionnaire.GetPageListData(parameter).then(res=>{
        console.log(res,"问卷无分页列表");
             if(res.data.Status==1){
                 this.QuestionnaireTable=res.data.Data
             }else{
                alert(res.data.Message)
             }
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";
.div_select{
  ::v-deep .el-input__inner{
   width: 530px !important;
  }
  ::v-deep .el-input__icon{
     line-height: 30px;
  }
}
  .relation_table{
      margin-bottom: 10px;
  }
  .input1{
    display: inline-block;
    width: 70px;
  }
  .input2{
    display: inline-block;
    width: 172px;
  }
  ::v-deep .el-input__inner{
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-form-item{
    margin-bottom: 0;
  }
  ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner{
      width: 242px;
  }
.addQuestionnaire{
  ::v-deep .el-dialog .el-dialog__header{
    margin-bottom:0;
  }
  ::v-deep .el-dialog__headerbtn{
        top:4px;
        font-size: 22px;
  }
  ::v-deep .el-dialog__body{
        padding: 0;
  }
}
.relation-Follow{
    ::v-deep .el-dialog__body{
   padding: 0px 30px 30px 30px;
  }
}
.main {
  width: 100%;
  min-width: 1440px;
  height: 100%;
  overflow: hidden;
  .box {
    width: calc(100% - 40px);
    height: calc(100% - 20px);
    box-sizing: border-box;
    padding-top: 20px;
    margin: 0 auto;
    .questionnaire-content {
      width: 100%;
      height: 100%;
      //margin-top: 20px;
      background: #fff;
      // padding: 20px 20px 0 20px;
      box-sizing: border-box;
      ::v-deep .el-button {
        padding-top: 10px;
        height: 34px;
        font-weight: 400;
        @include add-size5($font_size_14);
      }
      .header{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        span {
          @include common_span
        }
        ::v-deep .el-input {
          width: 240px;
          height: 34px;
          margin-right: 20px;
          .el-input__inner {
            width: 240px;
            height: 34px;
            @include add-size($font_size_16);
          }
        }
        ::v-deep .el-button {
          @include common_button_primary;
          margin-left: 20px;
        }
        .header-right {
          display: flex;
          cursor: pointer;
          &>div {
            display: flex;
            width: 139px;
            height: 34px;
            margin-left: 20px;
            background-size: 100% 100%;
          }
          .bg-left {
            background-image: url("../../assets/image/icon/questionnaire-left.png");
          }
          .bg-right  {
            background-image: url("../../assets/image/icon/questionnaire-right.png");
          }
        }
      }
    }
  }
}
.content {
  ::v-deep .el-table {
    .el-link {
      margin-right: 20px;
    }
  }
  ul {
   display: flex;
    width: 100%;
    flex-wrap: wrap;
    li {
      width: 300px;
      height: 200px;
      background: #FFFFFF;
      opacity: 1;
      border-radius: 10px;
      margin-right: 18px;
      margin-bottom: 18px;
      .card-title-text {
        width: 260px;
        p {
          width: 260px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -o-text-overflow:ellipsis;
        }
      }
      .questionnaire-num {
        width: 280px;
        text-align: center;
        p {
          font-size: 20px;
          line-height: 34px;
          color: #3388FF;
        }
        span {
          @include add-size5($font_size_14);
          font-weight: 400;
          color: #666666;
        }
      }
      .card_bottom {
       position: absolute;
        bottom: 0;
        height: 30px;
        span {
          @include add-size5($font_size_14);
          font-size: 14px;
          font-weight: 400;
          color: #AAAFB5;
        }
        .geng-duo {
          p {
            line-height: 40px;
            @include add-size($font_size_16);
            font-weight: 400;
            color: #585858;
          }
        }
      }
    }
  }
}
::v-deep .el-radio-group {
  margin-bottom: 0;
}
::v-deep .el-radio-button {
  //padding-top: 15px;
  height: 34px;
  .el-radio-button__inner{
    height: 34px;
    padding:9px 20px;
  }
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
::v-deep .el-breadcrumb__inner {
  margin-left: 6px;
  margin-right: 6px;
}
::v-deep .el-tag {
  height: 24px;
  padding-top: 0;
  line-height: 24px;
}
.active {
  padding-left: 20px;
  &:hover {
    background: #EDF7FF;
    color: #3388FF;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5){
  .main{
    .box{
      width: 100% !important;
    }
  }
}
</style>

<style>
.el-popover {
  padding: 0!important;
}
</style>
