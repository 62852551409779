<template>
  <div class="task-page">
    <div class="content-right">
      <div class="content-header">
        <p>
          <span></span>
          <span>课题概况</span>
        </p>
      </div>
      <div style="margin-left: 20px">
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/projecttype.png" alt="">
              </div>
              <span class="span-left">项目类型</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
                <div slot="content">
                  <p class="tooltip-content"></p>
                </div>
                <el-tooltip placement="top" :content="ProjectInformation.TypeName">
                  <span class="span-right">{{ProjectInformation.TypeName}}</span>
                </el-tooltip>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/subjectdirection.png" alt="">
              </div>
              <span class="span-left">项目方式</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <!-- <el-tooltip placement="top"> -->
                <div slot="content">
                  <p class="tooltip-content"></p>
                </div>
              <el-tooltip placement="top" :content="ProjectInformation.ModeName">
                <span class="span-right">{{ProjectInformation.ModeName}}</span>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/projectname.png" alt="">
              </div>
              <span class="span-left">项目名称</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <!-- <el-tooltip placement="top"> -->
                <div slot="content">
                  <p class="tooltip-content"></p>
                </div>
              <el-tooltip placement="top" :content="ProjectInformation.Name">
                <span class="span-right">{{ProjectInformation.Name}}</span>
              </el-tooltip>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/projectno.png" alt="">
              </div>
              <span class="span-left">主题方向</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <!-- <el-tooltip placement="top"> -->
                <div slot="content">
                  <p class="tooltip-content"></p>
                </div>
              <el-tooltip placement="top" :content="ProjectInformation.Direction">
                <span class="span-right">{{ProjectInformation.Direction}}</span>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/projectleads.png" alt="">
              </div>
              <span class="span-left">项目牵头单位(甲方)</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <!-- <el-tooltip placement="top"> -->
                <div slot="content">
                  <p class="tooltip-content"></p>
                </div>
                <!-- 初始的dm -->
                <!-- <span class="span-right">{{ProjectInformation.LDept}}</span> -->
                <el-tooltip placement="top" :content="ProjectInformation.LDeptName">
                <span class="span-right">{{ProjectInformation.LDeptName}}</span>
              </el-tooltip>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/projectleader.png" alt="">
              </div>
              <span class="span-left">项目编号</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <span class="span-right">{{ProjectInformation.No}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/time.png" alt="">
              </div>
              <span class="span-left">起止年限</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <span class="span-right" >{{ProjectInformation.StartDate!=null?ProjectInformation.StartDate.substr(0,10):''}}
                ~{{ProjectInformation.EndDate!=null?ProjectInformation.EndDate.substr(0,10):''}}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/projectleader.png" alt="">
              </div>
              <span class="span-left">项目负责人</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <span class="span-right">{{ProjectInformation.Leader}}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="content-textarea">
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/scientificcontent.png" alt="">
              </div>
              <span class="span-left">科研内容</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../assets/image/taskmanagement/scientificcontent.png" alt="">
              </div>
              <span class="span-left">管理员</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
               <span class="span-right">{{ProjectInformation.Admin}}</span>
            </div>
          </el-col>
        </el-row>
        <div>
          <el-input
            readonly
            type="textarea"
            :rows="6"
            v-model="ProjectInformation.ResearchContent">
          </el-input>
        </div>
        <div class="grid-content">
          <div class="task-img">
            <img src="../../assets/image/taskmanagement/assessmentindex.png" alt="">
          </div>
          <span class="span-left">考核指标</span>
        </div>
        <div>
          <el-input
            readonly
            type="textarea"
            :rows="5"
            v-model="ProjectInformation.TargetContent">
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectManagement from "../../api/ProjectManagement"
export default {
  data(){
    return{
      ProjectInformation: {},
    }
  },
  created(){
    this.QueryProject()
     console.log("项目信息");
  },
  methods:{
      async QueryProject(){
      const parameter=this.$route.query.Id
      ProjectManagement.QueryProjectData(parameter).then(res=>{
        if(res.data.Status==1){
            this.ProjectInformation=res.data.Data
        }else{
          alert(res.data.Message)
        }
        console.log(res,"项目信息");
      })
    }
  },
}
</script>
<style lang="scss" scoped>
@import "../../styles/mixin.scss";
.task-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content-right {
    width: 100%;
    height: 90%;
    padding: 1% 0 0 1%;
    box-sizing: border-box;
    margin-left: 20px;
    .content-header {
      margin-bottom: 18px;
      p {
        span:nth-child(1) {
          display: inline-block;
          width: 3px;
          height: 16px;
          background: #3388ff;
          margin-right: 10px;
          vertical-align: middle;
        }
        span:nth-child(2) {
          display: inline-block;
          color: #333333;
          @include add-size1($font_size_20);
          vertical-align: middle;
        }
      }
    }
  }
  .grid-content {
    display: flex;
    height: 36px;
    img {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
    span {
      //width: 30px;
      display: inline-block;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .span-left {
      color: #666;
      @include add-size4($font_size_18);
    }
    .span-right {
      color: #69696A;
      @include add-size4($font_size_18);
    }
  }
  .content-textarea {
    margin-left: 20px;
    ::v-deep .el-textarea {
      width: 95%;
      margin-left: 31px;
      margin-bottom: 15px;
      border: 1px solid #CBCBDE;
      opacity: 1;
      border-radius: 6px;
      @include add-size($font_size_16);
    }
  }
}
.tooltip-content {
  @include add-size($font_size_16);
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5){
  .task-page{
    .content-right{
      margin-left: 0 !important;
      padding: 0 !important;
    }
  }
}

</style>
