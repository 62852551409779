<template>
  <div>
    <el-button v-if="type==1" type="primary" icon="el-icon-plus" @click="AddMember">新增成员</el-button>
    <el-link @click="AddMember" v-if="type==2" :underline="false" style="margin-right: 11px"><i class="el-icon-edit"></i>修改</el-link>
    <div class="dialog_add">
      <el-dialog
        :visible.sync="dialogVisible"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age" v-if="type==1">新增成员</span>
           <span class="title-age" v-if="type==2">修改成员</span>
        </div>
       <el-form label-width="100px" :model="AddUserFrom" ref="AddUserFrom" :rules="rules">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="登录名:" prop="LoginName">
                <el-input v-model="AddUserFrom.LoginName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信:">
                <el-input v-model="AddUserFrom.WX"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="密码:" prop="PwdPlainText">
                <el-input v-model="AddUserFrom.PwdPlainText"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱:">
                <el-input v-model="AddUserFrom.EMail"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="姓名:" prop="Name">
                <el-input v-model="AddUserFrom.Name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职称:" required>
                <el-select v-model="AddUserFrom.Title" placeholder="请选择">
                  <el-option
                    v-for="(item,index) in titleList"
                    :key="index"
                    :label="item.Name"
                    :value="item.Code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="手机号:" prop="PhoneNumber">
                <el-input v-model="AddUserFrom.PhoneNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作单位:" required>
                  <el-select
                  ref="selectLabel2"
                  @change="WorkUnit"
                  v-model="AddUserFrom.WorkUnitId"
                  filterable
                  remote
                  :remote-method="UremoteMethod"
                  v-el-select-loadmore="loadmore"
                  placeholder="请选择">
                    <el-option
                      v-for="(item,index) in hospital2"
                      :key="index"
                      :label="item.Name"
                      :value="item.Id"
                      >
                    </el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="身份证:" prop="IdCardNo">
                <el-input v-model="AddUserFrom.IdCardNo" @change="change" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="科室:" required>
                  <el-select v-model="AddUserFrom.DeptId" placeholder="请选择">
                  <el-option
                    v-for="(item,index) in Department"
                    :key="index"
                    :label="item.Name"
                    :value="item.Code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="性别:" required>
                  <el-radio-group v-model="AddUserFrom.Sex">
                    <el-radio label="1">男</el-radio>
                    <el-radio label="0">女</el-radio>
                  </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="角色:" required>
                 <el-select v-model="AddUserFrom.RoleId" placeholder="请选择">
                  <el-option
                    v-for="(item,index) in role"
                    :key="index"
                    :label="item.Name"
                    :value="item.Id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="参研单位:" required>
                 <el-select
                  ref="selectLabel1"
                  @change="ResearchUnit"
                  v-model="AddUserFrom.ProjectUnitId"
                  filterable
                  remote
                  v-el-select-loadmore="loadmore1"
                  :remote-method="remoteMethod"
                  placeholder="请选择">
                    <el-option
                      v-for="(item,index) in hospital1"
                      :key="index"
                      :label="item.Name"
                      :value="item.Id"
                      >
                    </el-option>
                  </el-select>
                 <!-- <el-select placeholder="请选择" v-model="AddUserFrom.ProjectUnitId">
                  <el-option :label="item.UnitName" :value="item.UnitId" v-for="(item,index) in Companylist" :key="index"></el-option>
                </el-select> -->
                <!-- <el-button type="primary" style="margin-left: 10px;" @click="NewUnit">新增单位</el-button> -->
              </el-form-item>
            </el-col>
          </el-row>
       </el-form>
         <span slot="footer" class="btn">
          <button @click="CancelPersonnel">取消</button>
          <button type="primary" @click="AddPersonnel">保存</button>
        </span>
      </el-dialog>
    </div>
    <!-- <div class="Company">
        <el-dialog
            title="提示"
            :visible.sync="UdialogVisible"
            width="30%">
            <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">新增单位</span>
            </div>
            <div>
                <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="单位" prop="pass">
                    <span style="display: inline-block;width: 360px;">
                              <el-select
                            class="item_select"
                            v-model="ruleForm.UnitId"
                            filterable
                            remote
                            :remote-method="UremoteMethod"
                            placeholder="请选择">
                                <el-option
                                v-for="(item,index) in UnitMaintenance"
                                :key="index"
                                :label="item.Name"
                                :value="item.Id"
                                >
                                </el-option>
                            </el-select>
                    </span>
                    </el-form-item>
                    </el-form>
            </div>
            <div slot="footer" class="btn">
                <button @click="UdialogVisible = false">取消</button>
                <button type="primary" @click="AddPreservation">保存</button>
            </div>
        </el-dialog>
    </div> -->
     <div class="dialog_role">
      <el-dialog
        :visible.sync="roledialogVisible"
         fullscreen>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">新增成员</span>
        </div>
            <!-- <div style="margin-bottom: 10px;">
               <span style=" display: inline-block;width: 80px; text-align: right;font-size: 16px;">参研单位:</span>
                <el-select placeholder="请选择" v-model="xProjectUnitId" style="width: 360px;" @change="ProjectUnitIdChange">
                  <el-option :label="item.UnitName" :value="item.UnitId" v-for="(item,index) in Companylist" :key="index"></el-option>
                </el-select>
            </div>
             <div style="margin-bottom: 10px;">
              <span style=" display: inline-block;width: 80px; text-align: right;font-size: 16px;">角色:</span>
               <el-select v-model="xRoleId" placeholder="请选择" style="width: 360px;" @change="RoleIdChange">
                  <el-option
                    v-for="(item,index) in role"
                    :key="index"
                    :label="item.Name"
                    :value="item.Id">
                  </el-option>
                </el-select>
            </div> -->
            <div style="margin-bottom: 10px;text-align: right;">
              <el-button  type="primary" icon="el-icon-plus" @click="BatchOperation">批量操作</el-button>
<!--              <el-button  type="primary" icon="el-icon-plus" @click="xAddMember">新增成员</el-button>-->
            </div>
            <el-table
            :data="tableData"
            :header-cell-style="{ background: 'rgb(240, 247, 253)' }"
            border
            @selection-change="select"
            style="width: 100%;margin-bottom: 10px;">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              min-width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              label="参研单位"
              align="center"
            >
             <template slot-scope="scope">
                <el-select placeholder="请选择" v-model="scope.row.ProjectUnitId">
                  <el-option :label="item.UnitName" :value="item.UnitId" v-for="(item,index) in Companylist" :key="index"></el-option>
                </el-select>
             </template>
            </el-table-column>
            <el-table-column
              label="角色"
              align="center"
            >
             <template slot-scope="scope">
                <el-select placeholder="请选择" v-model="scope.row.RoleId">
                  <el-option :label="item.Name" :value="item.Id" v-for="(item,index) in role" :key="index"></el-option>
                </el-select>
             </template>
            </el-table-column>
            <el-table-column
              prop="LoginName"
              label="姓名"
              min-width="40"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="性别"
              min-width="40"
              align="center"
            >
              <template slot-scope="scope">
                    <p>{{scope.row.Sex==1?'男':''}}</p>
                    <p>{{scope.row.Sex==0?'女':''}}</p>
              </template>
            </el-table-column>
            <el-table-column
              prop="PhoneNumber"
              label="手机号"
              min-width="60"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="IdCardNo"
              label="身份证号"
              min-width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="EMail"
              label="邮箱"
              min-width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="WorkUnitName"
              label="所属医院"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="DeptName"
              label="所属科室"
              min-width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="TitleName"
              label="职称"
              min-width="80"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div class="btn">
            <button @click="roledialogVisible = false">取 消</button>
            <button type="primary" @click="ConfirmToJoin">确 定</button>
          </div>
      </el-dialog>
      </div>
      <div class="BatchOperation">
         <el-dialog
          title="提示"
          :visible.sync="BatchOperationVisible"
          width="30%">
         <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">新增成员</span>
        </div>
        <div>
           <div style="margin-bottom: 10px;">
               <span style=" display: inline-block;width: 80px; text-align: right;font-size: 16px;">参研单位:</span>
                <el-select placeholder="请选择" v-model="xProjectUnitId" style="width: 360px;">
                  <el-option :label="item.UnitName" :value="item.UnitId" v-for="(item,index) in Companylist" :key="index"></el-option>
                </el-select>
            </div>
             <div style="margin-bottom: 10px;">
              <span style=" display: inline-block;width: 80px; text-align: right;font-size: 16px;">角色:</span>
               <el-select v-model="xRoleId" placeholder="请选择" style="width: 360px;">
                  <el-option
                    v-for="(item,index) in role"
                    :key="index"
                    :label="item.Name"
                    :value="item.Id">
                  </el-option>
                </el-select>
            </div>
        </div>
          <div class="btn">
            <button @click="BatchOperationVisible = false">取 消</button>
            <button type="primary" @click="QueryBatchOperation">确 定</button>
          </div>
        </el-dialog>
      </div>
  </div>
</template>

<script>
import PatientCenter from "../api/PatientCenter"
import ProjectManagement from "../api/ProjectManagement"
import {debounce} from "../utils/AntiShakeThrottling"
import {validatePhone,validateregId,validatePassword} from "../utils/verification"
export default {
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function() {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  data() {
    return {
        BatchOperationVisible:false,
        xProjectUnitId:'',
        xRoleId:'',
        roledialogVisible:false,
        tableData:[],
        remoteName:'',
        remoteName1:'',
        selectPage:1,
        selectPage1:1,
        selectRows:10,
        rules: {
            LoginName:[
              {required: true, message: '登录名不能为空', trigger: 'blur' }
            ],
            Name:[
              {required: true, message: '姓名不能为空', trigger: 'blur' }
            ],
            PhoneNumber: [
              { validator: validatePhone, trigger: 'blur',required:true }
            ],
            // IdCardNo: [
            //   { validator: validateregId,trigger: 'blur'}
            // ],
            PwdPlainText: [
              { validator: validatePassword,trigger: 'blur',required:true}
            ],
        },
       dialogVisible:false,
       AddUserFrom:{
         LoginName:"",
         Name:"",
         IdCardNo:"",
         Sex:"",
         Title:"",
         PhoneNumber:"",
         WX:"",
         EMail:"",
         PwdPlainText:"",
         DeptId:"",
         WorkUnitId:'',
         RoleId:"",
         ProjectUnitId:"",
       },
       Department:[],
       hospital1:[],
       hospital2:[],
       role:[],
       titleList:[],
       Companylist:[],
       ruleForm:{
          ProjectId:this.$route.query.Id,
          UnitId:"",
          Id:"",
       },
       UnitMaintenance:[],
       UdialogVisible:false,
       list:[],
       //判断电话号码和身份证是否改变
       TelIsChange: '',
       CertNoIsChange: ''
    }
  },
  props:["type","Edit"],
  created(){
    console.log(this.Edit,'EditEdit');
    if(this.Edit!=null){
      this.AddUserFrom=JSON.parse(JSON.stringify(this.Edit));
      this.TelIsChange = this.Edit.PhoneNumber;
      this.CertNoIsChange = this.Edit.IdCardNo;
    }
  },
  methods: {
    //取消
    CancelPersonnel(){
      this.AddUserFrom = {}
      // this.AddUserFrom.WorkUnitId = '';
      // this.AddUserFrom.ProjectUnitId = '';
      this.$refs.AddUserFrom.resetFields();
      this.dialogVisible = false;
    },
        //工作单位
        WorkUnit(e){
           if(this.AddUserFrom.ProjectUnitId==''){
             this.$nextTick(()=>{
              this.Company(this.$refs.selectLabel2.selectedLabel,1)
              this.AddUserFrom.ProjectUnitId=e
            })
           }
        },
        //参研单位
        ResearchUnit(e){
           console.log(e);
           if(this.AddUserFrom.WorkUnitId==''){
            this.$nextTick(()=>{
              this.Company(this.$refs.selectLabel1.selectedLabel,2)
              this.AddUserFrom.WorkUnitId=e
            })
           }
        },
        select(row){
             this.list=row
             console.log(this.list);
        },
        QueryBatchOperation(){
            if(this.list.length==0){
                 alert('请先勾选')
                 return
            }
            if(this.xProjectUnitId!=''){
              this.ProjectUnitIdChange()
            }
            if(this.xRoleId!=''){
              this.RoleIdChange()
            }
            console.log(this.list,"$$");
            this.BatchOperationVisible=false
        },
        //批量操作
        BatchOperation(){
            this.xProjectUnitId=''
            this.xRoleId=''
            this.BatchOperationVisible=true
        },
        //角色
        RoleIdChange(){
          this.tableData=this.tableData.map(item=>{
               this.list.forEach(row=>{
                  if(item.Id==row.Id){
                    item.RoleId=this.xRoleId
                  }
               })
              return item
          })
        },
        //参研单位
        ProjectUnitIdChange(){
           this.tableData.forEach(item=>{
              this.list.forEach(row=>{
                  if(item.Id==row.Id){
                    item.ProjectUnitId=this.xProjectUnitId
                  }
              })
              // item.ProjectUnitId=this.xRoleId
              // return item
          })
        },
        //确定加入
        ConfirmToJoin(){
          //需要判断参研单位和角色(不能为空)
          if(this.list.length == 0){
            alert('请先勾选')
            return
          };
          // every所有item都符合时才会返回true some有一项不符合则返回false
          let result = this.list.every(item=>{
            return item.ProjectUnitId != '' && item.RoleId != '';
          });

          if(result){
            let list = this.list.map(item=>{
              return{
                UserId:item.Id,
                ProjectId:this.$route.query.Id,
                ProjectUnitId:item.ProjectUnitId,
                RoleId:item.RoleId,
              }
            });
            console.log(list,'list');
            ProjectManagement.DetermineMemberData(list).then(res=>{
              if(res.data.Status==1){
                  this.$emit("AddSonToFather",-1)
                  this.$message({
                            type: 'success',
                            message: '保存成功!'
                  });
                  this.dialogVisible=false
                  this.roledialogVisible=false
              }else{
                  alert(res.data.Message)
              }
               console.log(res,"########");
            })
          }else{
            alert('参研单位或者角色为空!')
          }

            // let list=this.tableData.map(item=>{
            //      return{
            //        UserId:item.Id,
            //        ProjectId:this.$route.query.Id,
            //        ProjectUnitId:item.ProjectUnitId,
            //        RoleId:item.RoleId,
            //      }
            // });

            // ProjectManagement.DetermineMemberData(list).then(res=>{
            //   if(res.data.Status==1){
            //       this.$emit("AddSonToFather",-1)
            //       this.$message({
            //                 type: 'success',
            //                 message: '保存成功!'
            //       });
            //       this.dialogVisible=false
            //       this.roledialogVisible=false
            //   }else{
            //       alert(res.data.Message)
            //   }
            //    console.log(res,"########");
            // })
        },
        ProjectMember(){
            const parameter={
                ProjectId:this.$route.query.Id,
                Key:'',
                page:1,
                rows:20,
            }
           ProjectManagement.ProjectMemberData(parameter).then(res=>{
               if(res.data.Status==1){
                  this.tableData=res.data.Data.rows.map(item=>{
                        // item['ProjectUnitId']=''
                        const IsHasId = this.Companylist.some(itemCompany=>{
                          return itemCompany.UnitId == item.WorkUnitId;
                        });
                        if(IsHasId){
                          item['ProjectUnitId'] = item.WorkUnitId;
                        }else{
                          item['ProjectUnitId'] = '';
                        }
                        return item
                  })
               }else{
                  alert(res.data.Message)
               }
               console.log(res,"成员");
           })
        },
        //保存单位
        AddPreservation(){
            ProjectManagement.AddCompanyData(this.ruleForm).then(res=>{
                    if(res.data.Status==1){
                        this.Companylist.push(res.data.Data)
                         this.$message({
                            type: 'success',
                            message: '保存成功!'
                        });
                        this.UdialogVisible=false
                    }else{
                        alert(res.data.Message)
                    }
                 console.log(res);
            })
        },
        // //单位列表接口
        // GetListCompany(Name){
        //       const parameter={
        //         Key:Name,
        //       }
        //       ProjectManagement.GetListCompanyData(parameter).then(res=>{
        //             if(res.data.Status==1){
        //                 this.UnitMaintenance=res.data.Data
        //                 // this.hospital1=JSON.parse(JSON.stringify(res.data.Data))
        //             }else{
        //                 alert(res.data.Message)
        //             }
        //             console.log(res,"医院");
        //       })
        // },
        UremoteMethod(name){
          this.hospital2 = [];
          this.selectPage = 1;
          this.remoteName = name;
          this.Company(name,2)
        },
        loadmore() {
          this.selectPage += 1;
          this.Company(this.remoteName,2,this.selectPage);
        },
        loadmore1() {
          this.selectPage1 += 1;
          this.Company(this.remoteName1,1,this.selectPage1);
        },
        //打开单位弹框
        NewUnit(){
            this.UdialogVisible=true
            this.GetListCompany('')
        },
        change(e){
            this.AddUserFrom.Sex=this.IdCard(e,2)
        },
        IdCard(IdCard, type) {
        if (type === 1) {
            //获取出生日期
            let birthday = IdCard.substring(6, 10) + "-" + IdCard.substring(10, 12) + "-" + IdCard.substring(12, 14)
            return birthday
        }
        if (type === 2) {
            //获取性别
            if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
                return "1"
            } else {
                return "0"
            }
        }
        if (type === 3) {
            //获取年龄
            var ageDate = new Date()
            var month = ageDate.getMonth() + 1
            var day = ageDate.getDate()
            var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
            if (IdCard.substring(10, 12) < month || IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day) {
                age++
            }
            if (age <= 0) {
                age = 1
            }
            return age
        }
    },
      //远程搜索单位医
      remoteMethod(name){
        this.hospital1 = [];
        this.selectPage1 = 1;
        this.remoteName1 = name;
        this.Company(name,1)
      },
      BaseDataBig(){
             if(this.type==1){
                this.Company('')
             }else{
              this.hospital1= [];
              this.hospital2= [];
               this.Company(this.AddUserFrom.ProjectUnitName,1)
               this.Company(this.AddUserFrom.WorkUnitName,2)
             }
          //所属单位
          const Company={
               ProjectId:[this.$route.query.Id]
          }
          ProjectManagement.NothingCompanyListData(Company).then(res=>{
                  if(res.data.Status==1){
                      this.Companylist=res.data.Data
                   }else{
                     alert(res.data.Message)
                   }
                  console.log(res,"单位");
          })
             const Department="KS"//科室
             PatientCenter.BaseDataBigData(Department).then(res=>{
                 if(res.data.Status==1){
                      this.Department=res.data.Data
                 }else{
                     alert(res.data.Message)
                 }
                 console.log(res,"科室");
             })
             const titleList="ZC"//科室
             PatientCenter.BaseDataBigData(titleList).then(res=>{
                  if(res.data.Status==1){
                        this.titleList=res.data.Data
                  }else{
                      alert(res.data.Message)
                  }
                 console.log(res,"职称");
             })
             const role={
                Key:""
             }
             ProjectManagement.RoleListData(role).then(res=>{//角色
                 if(res.data.Status==1){
                      this.role=res.data.Data
                 }else{
                     alert(res.data.Message)
                 }
                 console.log(res,"角色");
             })
             this.ProjectMember()
      },
      //单位列表接口
      Company(Name,num,selectPage){
             const parameter={
               Key:Name,
               page:selectPage ? selectPage : 1,
               rows:10
             }
             ProjectManagement.GetPageListCompanyData(parameter).then(res=>{
               console.log(res,"医院");
                  if(res.data.Status==1){
                      if(num==1){
                        // this.hospital1=JSON.parse(JSON.stringify(res.data.Data.rows))
                        res.data.Data.rows.forEach(item=>{
                          this.hospital1.push(item)
                        })
                      }
                      if(num==2){
                        res.data.Data.rows.forEach(item=>{
                          this.hospital2.push(item)
                        })
                        // this.hospital2=JSON.parse(JSON.stringify(res.data.Data.rows))
                      }
                  }else{
                      alert(res.data.Message)
                  }
             })
      },
      xAddMember(){
           this.dialogVisible=true
      },
      //打开新增成员弹框
      AddMember(){
        console.log(this.AddUserFrom);
        if(this.type==2){
          this.AddUserFrom=JSON.parse(JSON.stringify(this.Edit));
          this.dialogVisible=true
        }else{
          this.roledialogVisible=true
        }
        this.BaseDataBig()

      },
      //
      Vaild(){
        // row数据自带有projectId所以不需要在赋值
        // this.AddUserFrom.ProjectId=this.$route.query.Id
            const Edit= this.AddUserFrom
            ProjectManagement.EditUserData(Edit).then(res=>{
                if(res.data.Status==1){
                    this.$emit("EditSonToFather",res.data.Data)
                    this.dialogVisible=false
                     this.$message({
                        message: '修改成功',
                        type: 'success'
                      });
                }else{
                  alert(res.data.Message)
                }
               console.log(res,"编辑成员");
         })
      },
      // //新增成员
      AddPersonnel:debounce(function(){
        //          LoginName:"",
        //  Name:"",
        //  IdCardNo:"",
        //  Sex:"",
        //  Title:"",
        //  PhoneNumber:"",
        //  WX:"",
        //  EMail:"",
        //  PwdPlainText:"",
        //  DeptId:"",
        //  WorkUnitId:'',
        //  RoleId:"",
        //  ProjectUnitId:"",
        if(
        this.AddUserFrom.LoginName==""
        &&this.AddUserFrom.PwdPlainText==""
        &&this.AddUserFrom.Name==""
        &&this.AddUserFrom.PhoneNumber==""
        &&this.AddUserFrom.IdCardNo==""
        &&this.AddUserFrom.Sex==""
        &&this.AddUserFrom.Title==""
        &&this.AddUserFrom.WorkUnitId==""
        &&this.AddUserFrom.RoleId==""
        &&this.AddUserFrom.ProjectUnitId==""
        &&this.AddUserFrom.DeptId==""){
          alert("请填写必填项")
          return
        }
        if(this.type==1){
              this.AddUserFrom.ProjectId=this.$route.query.Id
              const parameter=this.AddUserFrom
              ProjectManagement.AddUserData(parameter).then(res=>{
                  if(res.data.Status==1){
                      this.$emit("AddSonToFather",res.data.Data)
                      this.dialogVisible=false
                      this.roledialogVisible=false
                         this.$message({
                          message: '新增成功',
                          type: 'success'
                        });
                        this.AddUserFrom = {};
                        this.$refs.AddUserFrom.resetFields();
                  }else{
                    alert(res.data.Message)
                  }
                 console.log(res,"新增成员");
              })
             return
        }
        if(this.type==2){
          //提交前的校验
          if(this.AddUserFrom.PhoneNumber != this.TelIsChange && this.AddUserFrom.IdCardNo == this.CertNoIsChange){
                this.$refs.AddUserFrom.validateField('PhoneNumber',(valid)=>{
                  if(!valid){
                    this.AddUserFrom.IdCardNo = '';
                    this.Vaild();
                  }
                })
              }else if(this.AddUserFrom.PhoneNumber == this.TelIsChange && this.AddUserFrom.IdCardNo != this.CertNoIsChange){
                this.$refs.AddUserFrom.validateField('IdCardNo',(valid)=>{
                  if(!valid){
                    this.AddUserFrom.PhoneNumber = '';
                    this.Vaild();
                  }
              })
              }else if(this.AddUserFrom.PhoneNumber == this.TelIsChange && this.AddUserFrom.IdCardNo == this.CertNoIsChange){
                this.AddUserFrom.PhoneNumber = '';
                this.AddUserFrom.IdCardNo = '';
                this.Vaild();
              }else{
                this.$refs.AddUserFrom.validateField('IdCardNo',(valid)=>{
                  if(!valid){
                    this.$refs.AddUserFrom.validateField('PhoneNumber',(valid1)=>{
                      if(!valid1){
                        this.Vaild();
                      }
                    })
                  }
                })
              };
          //清空变量
          this.TelIsChange = '';
          this.CertNoIsChange = '';



        //   this.$refs.AddUserFrom.validate((valid) => {
        //     if(valid){
        //       this.AddUserFrom.ProjectId=this.$route.query.Id
        //     const Edit=this.AddUserFrom
        //     ProjectManagement.EditUserData(Edit).then(res=>{
        //         if(res.data.Status==1){
        //             this.$emit("EditSonToFather",Edit)
        //             this.dialogVisible=false
        //              this.$message({
        //                 message: '修改成功',
        //                 type: 'success'
        //               });
        //         }else{
        //           alert(res.data.Message)
        //         }
        //        console.log(res,"编辑成员");
        //  })
        //     }else{
        //       this.$message({
        //               message:'还有内容未填写完成',
        //               type:'warning'
        //             })
        //     }
        //   })
        }
      })
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixin.scss";
::v-deep .el-dialog__body{
  text-align: left !important;
}
.Company{
  .item_select{
    width: 360px;
  }
}
.dialog_add {
::v-deep .el-dialog {
  width: 1200px;
  border-radius: 10px;

.el-dialog__footer{
  text-align: center;
}
.el-dialog__header {
  border-radius: 10px 10px 0px 0px;
  background: #3388ff;
.header-title {
.title-name {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: 5px;
}
.title-age {
  vertical-align: top;
  display: inline-block;
  @include add-size($font_size_16);
  color: #ffffff;
}
}
.el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
}

.btn {
  margin-bottom: 14px;
  text-align: center;
button:nth-child(1) {
  width: 180px;
  background: #ffffff;
  border: 1px solid #3388ff;
  height: 50px;
  border-radius: 10px;
  font-size: 24px;
  color: #3388ff;
  margin-right: 20px;
  cursor:pointer;
}
button:nth-child(2) {
  width: 180px;
  border: 1px solid #3388ff;
  background: #3388ff;
  height: 50px;
  border-radius: 10px;
  font-size: 24px;
  color: #fff;
  cursor:pointer;
}
}
}
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5){
    .dialog_add{

      ::v-deep .el-dialog{
        width: 700px !important;
      }
    }

    .BatchOperation{
      ::v-deep .el-dialog{
        width: 60% !important;
      }
    }
}
</style>
