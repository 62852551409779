<template>
  <div class="main">
    <div class="box">
      <div style="height: 30px;line-height: 30px;padding-top: 10px;box-sizing: border-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/HomePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>项目详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <div class="content-header">
          <el-button style="position: absolute;right: 0;top: 0" icon="el-icon-back" @click="clickreturn">返回</el-button>
          <div class="content-header-left">
            <div style="display: flex;height: 30px">
              <div style="width: 21px;height: 21px">
                <img src="../../assets/image/icon/project-info2.png" alt="" style="width: 21px;height: 21px;">
              </div>
              <span>{{ ProjectInformation.Name }}</span>
            </div>
            <div style="display:flex;">
              <div style="display: flex">
                <div style="width: 46px;height: 46px;margin-right: 4px;margin-top: 12px">
                  <img src="../../assets/image/icon/project-img46.png" alt="">
                </div>
                <div>
                  <p>{{ ProjectInformation.Leader }}</p>
                  <span>项目负责人</span>
                </div>
              </div>
              <div style="display: flex" class="projectPeo">
                <div style="width: 46px;height: 46px">
                </div>
                <div>
                  <p>{{ ProjectInformation.Admin }}</p>
                  <span>项目管理员</span>
                </div>
              </div>
              <div style="width: 0px;height: 40px;border: 1px solid #DFDFDF;margin:20px 47px 0 47px" class="line">

              </div>
              <div style="display: flex" class="projectTime">
                <div style="width: 46px;height: 46px;margin-top: 20px;margin-right: 16px">
                  <img src="../../assets/image/icon/project-zhong.png" alt="">
                </div>
                <div>
                  <p>{{ ProjectInformation.StartDate != null ? ProjectInformation.StartDate.substr(0, 10) : '' }}</p>
                  <span>项目开始时间</span>
                </div>
              </div>
              <div style="margin:20px 40px 0 40px;" class="zhi">
                <p>至</p>
              </div>
              <div style="display: flex">
                <div style="width: 46px;height: 46px;margin-top: 20px;margin-right: 16px">
                  <img src="../../assets/image/icon/project-zhong.png" alt="">
                </div>
                <div>
                  <p>{{ ProjectInformation.EndDate != null ? ProjectInformation.EndDate.substr(0, 10) : '' }}</p>
                  <span>项目结束时间</span>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex" class="timerspace">
            <div style="display: flex">
              <div
                style="margin-top:34px;margin-right:10px;width: 50px;height: 50px;background: #EAF0FF;border-radius: 10px;padding: 13px 13px 0 13px;box-sizing: border-box">
                <img src="../../assets/image/icon/project-t.png" alt="" style="vertical-align: middle">
              </div>
              <div class="header-right">
                <span>{{ ProjectInformation.Cycle }}天</span>
                <p>项目周期</p>
              </div>
            </div>
            <div>
              <div class="project-status">
                <span>{{ ProjectInformation.State }}</span>
              </div>
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="TabClick">
          <el-tab-pane label="项目信息" name="first">
            <project-info v-if="activeName == 'first'"></project-info>
          </el-tab-pane>
          <el-tab-pane label="参研单位" name="Company">
            <UnitMaintenance v-if="activeName == 'Company'"></UnitMaintenance>
          </el-tab-pane>
          <el-tab-pane label="参研人员" name="second">
            <Participants v-if="activeName == 'second'"></Participants>
          </el-tab-pane>
          <el-tab-pane label="患者列表" name="three">
            <patient-list v-if="activeName == 'three'"></patient-list>
          </el-tab-pane>
          <el-tab-pane label="问卷列表" name="four">
            <ProjectQuestionnaireList v-if="activeName == 'four'"></ProjectQuestionnaireList>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import PatientList from "@/components/projectManagement/PatientList";
import Participants from "@/components/projectManagement/Participants";
import ProjectInfo from "@/components/projectManagement/ProjectInfo";
import ProjectQuestionnaireList from "@/components/projectManagement/ProjectQuestionnaireList";
import UnitMaintenance from "@/components/projectManagement/UnitMaintenance"
import ProjectManagement from "../../api/ProjectManagement"
import { mapState } from 'vuex'
export default {
  name: "SeeProject",
  data() {
    return {
      activeName: "first",
      ProjectInformation: {}
    }
  },
  components: {
    ProjectInfo,
    Participants,
    PatientList,
    ProjectQuestionnaireList,
    UnitMaintenance,
  },
  computed: {
    ...mapState(['ElTabs']),
    date(start, end) {
      let day = Date.parse(end) - Date.parse(start)
      let days = parseInt(day / (1000 * 60 * 60 * 24));
      return days
    }
  },
  created() {
    console.log(this.ElTabs);
    this.activeName = this.ElTabs
    this.QueryProject()
  },
  methods: {
    clickreturn() {
      //  this.$router.push({
      //   path: "/HomePage",
      // })
      this.$router.go(-1);
    },
    TabClick(item) {
      switch (item.index) {
        case "0":
          this.$store.commit("ElTabsFn", "first");
          this.activeName = this.ElTabs
          break
        case "1":
          this.$store.commit("ElTabsFn", "Company");
          this.activeName = this.ElTabs
          break
        case "2":
          this.$store.commit("ElTabsFn", "second");
          this.activeName = this.ElTabs
          break
        case "3":
          this.$store.commit("ElTabsFn", "three");
          this.activeName = this.ElTabs
          break
        case "4":
          this.$store.commit("ElTabsFn", "four");
          this.activeName = this.ElTabs
          break
      }
      console.log(item);
    },
    //项目信息
    async QueryProject() {
      const parameter = this.$route.query.Id
      ProjectManagement.QueryProjectData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.ProjectInformation = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, "项目信息");
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";

.main {
  width: 100%;
  min-width: 1440px;
  height: 100%;
  overflow: hidden;

  .box {
    width: calc(100% - 40px);
    height: calc(100% - 50px);
    margin: 0 auto;
    border-radius: 10px;

    .content {
      width: 100%;
      height: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      overflow: auto;

      ::v-deep .el-button {
        @include common_button_primary
      }

      .content-header {
        width: 100%;
        height: 120px;
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 21px 81px 19px 50px;
        box-sizing: border-box;

        .content-header-left {
          p {
            font-size: 18px;
            font-weight: 400;
            line-height: 41px;
            color: #333333;
          }

          span {
            @include common_span
          }
        }

        .header-right {
          width: 120px;
          margin-right: 40px;
          margin-top: 30px;

          p {
            width: 120px;
            @include common_span;
          }

          span {
            font-size: 24px;
            color: #3388FF;
          }
        }

        .header-right2 {
          width: 120px;
          text-align: center;

          p {
            width: 120px;
            @include common_span;
            color: #FF9900;
            ;
          }

          span {
            font-size: 34px;
            color: #FF9900;
          }
        }
      }
    }

    ::v-deep .el-tabs__item:focus.is-active.is-focus:not(:active) {
      box-shadow: none;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    ::v-deep .el-tabs__item {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
  }
}

.project-status {
  position: absolute;
  right: 0;
  top: 55px;
  width: 120px;
  height: 48px;
  text-align: center;
  background: linear-gradient(134deg, #00CFE6 0%, #17EBD5 100%);
  border-radius: 24px 0 0 24px;

  span {
    @include add-size($font_size_16);
    font-weight: 400;
    color: #FFFFFF;
    line-height: 48px;
  }
}

::v-deep .el-tabs__nav-wrap::after {
  height: 2px;
}

::v-deep .el-tabs__active-bar {
  height: 4px;
  //background: transparent;
}


@media screen and (-webkit-min-device-pixel-ratio: 1.5) {

  .main {
    min-width: 0 !important;

    .content {
      // overflow: hidden !important;
      overflow-y: scroll;

      .content-header {
        padding: 0 !important;

        img {
          margin-right: 0 !important;
        }

        .projectPeo {
          div:nth-child(1) {
            width: 0 !important;
          }
        }

        .line {
          margin: 20px 0px 0px !important;
        }

        .projectTime {

          div:nth-child(1) {
            margin-right: 0 !important;
          }
        }

        .zhi {
          margin: 20px 0px 0px !important;
        }

        .timerspace {
          display: none !important;
        }
      }


    }
  }

}</style>

