<template>
    <div>
        <!-- <div style="margin-bottom: 10px;" v-for="(item,index) in InterruptArgs" :key="index"> -->
               <span style="width: 180px;text-align: right;margin-right: 20px;" v-show="index==0"></span>
               <span v-show="index==0">CRF表</span>
               <span v-show="index!=0" style="width:80px;margin-left: 160px;">
                    <el-select v-model="item.relation" placeholder="请选择">
                        <el-option
                        v-for="(item,index) in OrAnd"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
               </span>
               <span style="width: 180px;margin-right:10px;margin-left: 10px;">
                   <el-input v-model="item.QidName" placeholder="请输入内容" disabled></el-input>
               </span>
               <span style="margin-right:10px;">中</span>
                  <span style="width: 180px;margin-right:10px;">
                    <el-select v-model="field" placeholder="" @change="FieldChange($event,item)" value-key="id">
                        <el-option
                        v-for="(item,index) in FieldList"
                        :key="index"
                        :label="item.name"
                        :value="item">
                        </el-option>
                    </el-select>
                  </span>
                 <span style="width: 90px;margin-right:10px;">
                    <el-select v-model="item.operator" placeholder="请选择">
                        <el-option
                        v-for="(item,index) in condition"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                  </span>
               <span style="width: 180px;">
                   <el-input v-model="item.value" placeholder="请输入内容"></el-input>
               </span>
               <span class="el-icon-circle-plus-outline" @click="add"></span>
               <span class="el-icon-remove-outline" @click="del(item)"></span>
          <!-- </div> -->
    </div>
</template>
<script>
import ProjectManagement from "../../api/ProjectManagement"
export default {
    data(){
        return{
            field:{},
            FieldList:[],//题目数组
            OrAnd:[
                {
                value: 'AND',
                label: '且'
                }, {
                value: 'OR',
                label: '或'
                },
            ],
            condition:[
            {
                value: '=',
                label: '等于'
            },
            {
                value: '!=',
                label: '不等于'
            },
            {
                value: '>',
                label: '大于'
            },
            {
                value: '<',
                label: '小于'
            }
            ],
        }
    },
    props:['item','index'],
    created(){
       this.ListOfTopics()
       this.field['id']=this.item.field
    },
    methods:{
        FieldChange(e,item){
            item.field=e.id
            item.qtype=e.dataName
        },
         //题目列表
        ListOfTopics(){
         const parameter={
              projectQuestionnaireId:this.item.qid
         }
         ProjectManagement.ListOfTopicsData(parameter).then(res=>{
                  if(res.data.Status==1){
                     this.FieldList=res.data.Data
                  }else{
                     alert(res.data.Message)
                  }
                   console.log(res,"题目列表");
         })
        },
       //新增
        add(){
            this.$emit('Eaddcondition')
        },
        //删除
        del(item){
           this.$emit('Edelcondition',item.id)
        }
    }
}
</script>
<style lang="scss" scoped>
.el-icon-circle-plus-outline{
    vertical-align: middle;
    font-size: 20px;
    color: #3388FF;
    margin-left: 10px;
    cursor:pointer;
}
.el-icon-remove-outline{
    vertical-align: middle;
    font-size: 20px;
    color: #3388FF;
    margin-left: 10px;
    cursor:pointer;
}
span{
    display: inline-block;
}
</style>