<template>
  <div>
    <div class="content-btn"
      style="display: flex;justify-content:space-between;width: 98%;margin:0 auto;margin-bottom: 20px">
      <div class="query-input">
        <div>
          <span class="query-key">关键字:</span>
          <el-input placeholder="请输入关键字搜索" v-model.trim="listQuery.Key"></el-input>
        </div>
        <div>
          <span class="query-key">姓名:</span>
          <el-input placeholder="请输入姓名搜索" v-model.trim="listQuery.Name"></el-input>
        </div>
        <div>
          <span class="query-key">电话号码:</span>
          <el-input placeholder="请输入电话号码搜索" v-model.trim="listQuery.PhoneNumber"></el-input>
        </div>
        <div>
          <span class="query-key">参研单位名称:</span>
          <el-input placeholder="请输入参研单位名称搜索" v-model.trim="listQuery.ProjectUnitName"></el-input>
        </div>
        <div>
          <span class="query-key">工作单位名称:</span>
          <el-input placeholder="请输入工作单位名称搜索" v-model.trim="listQuery.WorkUnitName"></el-input>
        </div>
        <div>
          <span class="query-key">科室名称:</span>
          <el-input placeholder="请输入科室名称搜索" v-model.trim="listQuery.DeptName"></el-input>
        </div>
        <el-button @click="query" icon="el-icon-search" style="margin-left: 20px">查询</el-button>
      </div>
      <div>
        <adduser v-if="admin != '00000000-0000-0000-0000-000000000000'" :type="1" @AddSonToFather="Addfather"></adduser>
      </div>
    </div>
    <el-table style="width: 98%;margin:0 auto;" height="500px" :data="tableData" border
      :header-cell-style="{ background: 'rgb(240, 247, 253)' }">
      <el-table-column v-if="admin == '00000000-0000-0000-0000-000000000000'" prop="OrganizationName" label="组织名称"
        min-width="80" align="center">
      </el-table-column>
      <el-table-column prop="Name" label="姓名" align="center" :sortable="true" :sort-method="sortByName" width="120">
      </el-table-column>
      <el-table-column prop="LoginName" label="登录名" align="center" width="120">
      </el-table-column>
      <el-table-column prop="PhoneNumber" label="电话" align="center" min-width="170">
      </el-table-column>
      <el-table-column label="性别" align="center" width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.Sex }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="RoleName" label="角色" width="180">
      </el-table-column>
      <el-table-column prop="ProjectUnitName" label="参研单位" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="WorkUnitName" label="工作单位" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="DeptName" label="科室" min-width="180" align="center">
      </el-table-column>
      <el-table-column align="center" prop="IdCardNo" label="身份证" min-width="190">
      </el-table-column>
      <el-table-column label="操作" width="230" align="center" v-if="admin != '00000000-0000-0000-0000-000000000000'">
        <template slot-scope="scope">
          <div style="display: flex;justify-content:center;">
            <!-- <el-link :underline="false" style="margin-right: 11px" ><i class="el-icon-search"></i>查看</el-link> -->
            <adduser :type="2" @EditSonToFather="Editfather" :Edit="scope.row" v-show="scope.row.RoleType != 'D'">
            </adduser>
            <el-link :underline="false" @click="DeleteParticipants(scope.row)" v-show="scope.row.RoleType != 'D'"><i
                class="el-icon-delete"></i>删除</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="width: 98%;margin:0 auto;">
    </div>
    <Pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.rows" @pagination="GetPageListTable">
    </Pagination>
  </div>
</template>

<script>
import adduser from "@/components/AddUser";
import ProjectManagement from "../../api/ProjectManagement"
import { local } from "../../utils/storage";
import Pagination from "../Pagination/index.vue"
export default {
  name: "Participants",
  data() {
    return {
      admin: local.get("UserId"),
      delUserdialog: false,
      tableData: [],
      keyword: "",
      total: 0,
      listQuery: {
        Key: '',
        Name: '',
        PhoneNumber: '',
        ProjectUnitName: '',
        WorkUnitName: '',
        DeptName: '',
        page: 1,
        rows: 10
      }
    }
  },
  components: {
    adduser,
    Pagination
  },
  created() {
    console.log("参与人员")
    this.GetPageList();
  },
  methods: {
    sortByName(obj1, obj2) {
      if ([obj1.Name, obj2.Name][0] == [obj1.Name, obj2.Name].sort((a, b) => a.localeCompare(b, "zh", { sensitivity: 'accent' }))[0]) {
        return -1
      } else {
        return 1
      }
    },
    query() {
      // return
      this.tableData = [];
      this.GetPageList(1);
    },
    //成员列表
    GetPageListTable() {
      this.tableData = [];
      this.GetPageList();
    },
    async GetPageList(page) {
      const parameter = {
        ProjectId: [this.$route.query.Id],
        Key: this.listQuery.Key,
        page: page ? page : this.listQuery.page,
        rows: this.listQuery.rows,
        Name: this.listQuery.Name,
        PhoneNumber: this.listQuery.PhoneNumber,
        ProjectUnitName: this.listQuery.ProjectUnitName,
        WorkUnitName: this.listQuery.WorkUnitName,
        DeptName: this.listQuery.DeptName,
      }
      ProjectManagement.GetPageListData(parameter).then(res => {
        if (res.data.Status == 1) {
          this.tableData = res.data.Data.rows;
          this.total = res.data.Data.total;
        } else {
          alert(res.data.Message)
        }
        console.log(res, "参与人员列表");
      })
    },
    //删除成员
    DeleteParticipants(row) {
      const parameter = []
      parameter.push(row.Id);
      console.log(row);
      this.$confirm('此操作将永久删除该成员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ProjectManagement.DeleteUserData(parameter).then(res => {
          if (res.data.Status == 1) {
            //  this.tableData=this.tableData.filter(item=>{
            //      return item.Id!=row.Id
            // })
            this.tableData = [];
            this.GetPageList();
          } else {
            alert(res.data.Message)
          }
          console.log(res);
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {

      })

    },
    //新增成员
    Addfather(data) {
      if (data == -1) {
        this.tableData = [];
        this.GetPageList()
      } else {
        this.tableData.unshift(data)
      }
      console.log(data);
    },
    //修改成员
    Editfather(data) {
      this.GetPageList();
      this.tableData = this.tableData.map(item => {
        if (item.id == data.Id) {
          item = data
        }
        return item
      })
      console.log(this.tableData, data);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";

.query-input {
  display: flex;
  gap: 15px;
  margin-right: 10px;

  &>div {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    align-items: center;

    .el-input {
      width: 100%;

      :deep(.el-input__inner) {
        width: 100% !important;
        font-size: 14px;
        padding: 0 5px;
      }
    }
  }

  .query-key {
    // @include common_span;
    line-height: 34px;
  }

  ::v-deep .el-input {
    width: 240px;
    height: 34px;

    .el-input__inner {
      width: 240px;
      height: 34px;
      @include add-size($font_size_16)
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {}
</style>
